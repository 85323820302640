import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Context } from '../../lib/Context';

function ValidateEmailModal() {
    const { API_LINK, showValidateEmailModal, setShowValidateEmailModal, userInfo } = useContext(Context)
    const [validationCode, setValidationCode] = useState('')
    const [showInvalidCodePrompt, setShowInvalidCodePrompt] = useState(false);
    const email = userInfo?.email

    const handleInput = (event) => {
        setShowInvalidCodePrompt(false);
        const value = event.target.value;
        setValidationCode(value);
    }

    const validateEmail = async () => {
        const response = await fetch(API_LINK + '/api/validate-email', {
            method: 'POST',
            body: JSON.stringify({ validationCode }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(data => {
                if (data) {
                    setShowValidateEmailModal(false);
                } else {
                    setShowInvalidCodePrompt(true);
                }
            });
        } else {
            console.log("Error");
        }
    }

    return (
        <Modal show={showValidateEmailModal}>
            <Modal.Header>
                <Modal.Title>Please validate your email address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                We have sent a 6-digit validation code to {email}. Please check your email and enter in the code{' '}
                below. To validate your email address.
                <Form style={{ marginTop: '20px' }}>
                    <Form.Group className="mb-3" controlId="validation-code">
                        <Form.Control type="text" name="validation-code" onChange={handleInput} value={validationCode}
                            placeholder="Validation Code" />
                    </Form.Group>
                </Form>
                {showInvalidCodePrompt &&
                    <Alert variant="danger">
                        That code is incorrect!
                    </Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ minWidth: '100%', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                    onClick={validateEmail}>
                    VALIDATE EMAIL
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ValidateEmailModal