import React, { useContext } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Context } from '../../../../lib/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faReceipt } from '@fortawesome/free-solid-svg-icons';

const Invoices = (props) => {
    // contract, service
    const { API_LINK, userInfo, setInvoiceModalID, setShowInvoiceModal, setInvoices } = useContext(Context)
    const { invoice } = props;
    const type = userInfo?.type;

    const markAsPaid = async () => {
        const response = await fetch(API_LINK + '/api/mark-as-paid', {
            method: 'POST',
            body: JSON.stringify({ invoiceID: invoice._id }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setInvoices(data.invoices)
            });
        } else {
            alert("Couldn't validate code");
        }
    }


    return (
        <>
            <Card style={{ paddingTop: '12px', paddingLeft: '20px', paddingRight: '20px', marginBottom: '10px' }}>
                <Row>
                    <Col xs={7} md={9}>
                        <strong>{invoice.jobName}</strong>
                    </Col>
                    {type === 'admin' &&
                        <Col xs={5} md={3}>
                            {invoice.isPaid ?
                                <span style={{ color: '#99E63C', cursor: 'pointer' }} onClick={markAsPaid}>
                                    <FontAwesomeIcon icon={faCircle} /> Paid
                                </span>
                                :
                                <span style={{ color: '#E63C99', cursor: 'pointer' }} onClick={markAsPaid}>
                                    <FontAwesomeIcon icon={faCircle} /> Unpaid
                                </span>
                            }
                        </Col>
                    }
                    {(type === 'client' || type === 'site') &&
                        <Col xs={5} md={3}>
                            {invoice.isPaid ?
                                <span style={{ color: '#99E63C' }}>
                                    <FontAwesomeIcon icon={faCircle} /> Paid
                                </span>
                                :
                                <span style={{ color: '#E63C99' }}>
                                    <FontAwesomeIcon icon={faCircle} /> Unpaid
                                </span>
                            }
                        </Col>
                    }
                </Row>
                <Row>
                    <Col xs={7} md={3}>
                        Date: {invoice.invoiceDate}
                    </Col>
                    <Col xs={5} md={3}>
                        Due: {invoice.dueDate}
                    </Col>
                    <Col xs={7} md={3}>
                        #{invoice.invoiceNumber}
                    </Col>
                    <Col xs={5} md={3}>
                        £{invoice.jobPrice.toFixed(2)}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button style={{
                            marginRight: '10px', backgroundColor: '#ffffff', color: '#E6893B', borderColor: '#E6893B',
                            cursor: 'pointer', marginBottom: '15px', marginTop: '15px', width: '100%'
                        }} onClick={() => {
                            setInvoiceModalID(invoice._id);
                            setShowInvoiceModal(true);
                        }}>
                            <FontAwesomeIcon icon={faReceipt} /> VIEW INVOICE
                        </Button>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default Invoices