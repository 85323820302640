import logo from '../../lib/Images/ecshome.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SimplePave from '../../lib/Images/SimplePave.jpg'
import MotorHome from '../../lib/Images/MotorHome.jpg'
import House from '../../lib/Images/House.jpg'
import '../../css/ServicesPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react';
import { Context } from '../../lib/Context';

const HowItWorks = () => {
    const { setShowContactModal } = useContext(Context)
    return (
        <>
            <img src={logo} alt="Website Logo" style={{ maxWidth: '100%', marginTop: '60px' }} />
            <Row>
                <Col style={{ textAlign: 'center', marginTop: '30px', marginBottom: '30px' }}>
                    <h1>How it works</h1>
                </Col>
            </Row>
            <Row style={{ marginTop: '5px' }}>
                <Col xs={12} lg={6}>
                    <img style={{ width: '100%', height: 'auto', marginBottom: '50px' }} src={SimplePave} alt="Simple Paving" />
                </Col>
                <Col xs={12} lg={6}>
                    <div className='how-it-works'>
                        <h2>How the packages work</h2>
                        <p>First choose the services you would like, we usually work on an 8 weekly cycle,{' '}
                            then drop us an email mentioning any special requirements you may need,{' '}
                            or you can send us a text and we will be in contact shortly.</p>

                        <p>AFTER THE FIRST CLEAN, our servitor will confirm your package, its frequency{' '}
                            and how much the standing order will be monthly.</p>
                        <p>Once you are happy with the services we will be providing and you set up your{' '}
                            standing order, we take care of the rest, it's that simple.</p>

                        <p>And feel free to ask for quotes on any other services you might be interested in,{' '}
                            you might be surprised at how affordable a REGULAR property maintenance package can be.</p>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: '5px' }}>
                <Col xs={12} lg={6}>
                    <div className='how-it-works'>
                        <h2 style={{ marginBottom: '30px' }}>The 1 year package can include</h2>
                        <FontAwesomeIcon icon={faCheck} /> Window cleaning<br />
                        <FontAwesomeIcon icon={faCheck} /> Gutter cleaning<br />
                        <FontAwesomeIcon icon={faCheck} /> Solar panel cleaning<br />
                        <FontAwesomeIcon icon={faCheck} /> Fascia and soffit cleaning<br />
                        <FontAwesomeIcon icon={faCheck} /> Cladding<br />
                        <FontAwesomeIcon icon={faCheck} /> Pressure washing<br />
                        <FontAwesomeIcon icon={faCheck} /> Grass cutting<br />
                        <FontAwesomeIcon icon={faCheck} /> Hedge trimming
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <img style={{ width: '100%', height: 'auto', marginBottom: '50px' }} src={MotorHome} alt="Motor Home" />
                </Col>
            </Row>
            <Row style={{ backgroundColor: 'black', marginTop: '0px', marginBottom: '50px' }}>
                <Col style={{ textAlign: 'center', marginTop: '50px', marginBottom: '50px', color: 'white' }}>
                    <h1>Contact Us</h1>
                    <p style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
                        If you would like to get in touch with us then you can email us by{' '}
                        <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { setShowContactModal(true) }}>
                            clicking here
                        </span>
                        , or you can WhatsApp us{' '}
                        images of the job you would like us to do for you using 07383618032 and we'll get back to you ASAP.
                    </p>
                </Col>
            </Row>
            <Row style={{ marginTop: '5px' }}>
                <Col xs={12} lg={6}>
                    <img style={{ width: '100%', height: 'auto', marginBottom: '50px' }} src={House} alt="House" />
                </Col>
                <Col xs={12} lg={6}>
                    <div className='how-it-works'>
                        <h2>Special Offers</h2>
                        <h3>The Estate Agent Special</h3>

                        <p>This special is for the home owner that wants maximum maintenance regularly or{' '}
                            for the home owner that's selling their house and wants to sell at an increased{' '}
                            price as its known, image matters.</p>

                        <p>Research has shown that a well maintained property can sell for up to 8% more on the market{' '}
                            this service can be used as a one off or as a regular service</p>

                        <p style={{ cursor: 'pointer' }} onClick={() => { setShowContactModal(true) }}>
                            <strong><FontAwesomeIcon icon={faEnvelope} /> Please get in touch for further details</strong>
                        </p>
                    </div>
                </Col>
            </Row>

        </>
    )
}

export default HowItWorks;