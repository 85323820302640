import "react-datepicker/dist/react-datepicker.css";


import Card from 'react-bootstrap/Card';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPenToSquare, faHourglassHalf, faWallet,
    faComments, faFileSignature, faStoreAlt, faUserTie, faTrashCan, faAddressBook, faLocationDot, faPhone
} from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react';
import { Context } from '../../../../lib/Context';
import ListServices from "../ListServices";

const Contract = props => {
    const { setShowApproveJobModal, setApproveContractID, setShowEditContractModal,
        setShowListSitesModal, setEditContractID, userInfo, services } = useContext(Context)
    const { jobDuration, siteName, jobDescription, isLive, endDate, siteAddress,
        sitePostcode, siteContactName, siteContactNumber, monthlyFee } = props;
    const date = endDate.split('T')[0];
    const day = date.split('-')[2];
    const month = date.split('-')[1];
    const year = date.split('-')[0];
    const contractID = props._id;
    const type = userInfo?.type;
    return (

        <Card body>
            <>
                <Row>
                    <Col xs={12} sm={6} md={4} >
                        <FontAwesomeIcon icon={faStoreAlt} /> {siteName}<br />
                        <FontAwesomeIcon icon={faAddressBook} /> {siteAddress}<br />
                        <FontAwesomeIcon icon={faLocationDot} /> {sitePostcode}
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                        <FontAwesomeIcon icon={faWallet} />{' '}
                        {monthlyFee === 0 ? 'Pay Per Job' : <> Pay Monthly (£{monthlyFee.toFixed(2)})</>}
                    </Col>
                    <Col xs={12} sm={6} md={4} >
                        <FontAwesomeIcon icon={faUserTie} /> {siteContactName}<br />
                        <FontAwesomeIcon icon={faPhone} /> <a href={"tel:" + siteContactNumber}>{siteContactNumber}</a><br />
                    </Col>
                </Row>
                <hr />
                <Row>
                    {services?.map((service, index) => (
                        <ListServices key={index} {...service} currentContractID={contractID} />
                    ))}
                </Row>
                <hr />
                <Row style={{ marginTop: '20px' }}>
                    <Col xs={12} md={5} lg={8}>
                        <FontAwesomeIcon icon={faComments} /> {jobDescription}
                    </Col>
                    {isLive &&
                        <Col xs={12} md={7} lg={4}>
                            <div style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={faFileSignature} /> {jobDuration}<br />
                                <FontAwesomeIcon icon={faHourglassHalf} /> <span style={{ color: '#32CD32', fontWeight: 'bold' }}>
                                    {day + ' ' + month + ' ' + year}
                                </span>
                            </div>
                        </Col>
                    }
                    {!isLive &&
                        <>
                            <Col xs={12} md={7} lg={4} style={{ paddingTop: '7px', textAlign: 'right' }}>
                                {type === 'admin' &&
                                    <>
                                        <Button onClick={() => {
                                            setEditContractID(contractID);
                                            setShowListSitesModal(false);
                                            setShowEditContractModal(true);
                                        }}>
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </Button>{' '}
                                    </>
                                }
                                <Button onClick={() => {
                                    setShowApproveJobModal(true);
                                    setApproveContractID(contractID);
                                }} style={{ backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}>
                                    APPROVE
                                </Button>{' '}
                                {type === 'admin' &&
                                    < Button style={{ backgroundColor: '#E6893B', borderColor: '#E6893B' }}>
                                        <FontAwesomeIcon icon={faTrashCan} />
                                    </Button>
                                }
                            </Col>
                        </>
                    }
                </Row>
            </>
        </Card>

    )
}

export default Contract;