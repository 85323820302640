
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../../css/Job.css'
import {
    faAddressBook,
    faFileLines,
    faLocationDot, faMagnifyingGlass, faStoreAlt, faTruckFast
} from '@fortawesome/free-solid-svg-icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Context } from '../../../../lib/Context';

const SiteClientContract = (props) => {
    const { siteName, jobName, subContractorID, siteAddress, sitePostcode,
        dateAttended, jobPrice } = props;
    const { API_LINK, invoices, setShowJobDetailsModal,
        userInfo, setDetailsJobModalID, setInvoiceModalID, setShowInvoiceModal } = useContext(Context);
    const [subContractorName, setSubContractorName] = useState('')
    const attendedDate = new Date(dateAttended);
    let attendedDay = attendedDate.getDate();
    let attendedMonth = attendedDate.getMonth() + 1;
    let attendedYear = attendedDate.getFullYear();
    const jobID = props._id;
    const type = userInfo?.type;

    const getSubContractorsName = useCallback(() => {
        fetch(API_LINK + '/api/get-sub-contractor-contact/' + subContractorID, {
            credentials: 'include',
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                setSubContractorName(data.name)
            })
        })
    }, [API_LINK, setSubContractorName, subContractorID])

    useEffect(() => {
        getSubContractorsName();
    }, [getSubContractorsName]);

    const findInvoiceID = () => {
        for (let i = 0; i < invoices?.length; i++) {
            if (jobID === invoices[i]?.jobID) {
                setInvoiceModalID(invoices[i]._id)
                setShowInvoiceModal(true);
            }
        }
    }

    return (
        <>
            <Card body style={{ marginBottom: '20px' }}>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={6} lg={3} >
                            <FontAwesomeIcon icon={faStoreAlt} /> {siteName}<br />
                            <FontAwesomeIcon icon={faTruckFast} /> {jobName}
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <FontAwesomeIcon icon={faAddressBook} /> {siteAddress}<br />
                            <FontAwesomeIcon icon={faLocationDot} /> {sitePostcode}
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            Completed by: <br />
                            <span style={{ fontWeight: 'bold', width: '100%' }}>
                                {subContractorName} ({attendedDay}/{attendedMonth}/{attendedYear}){' '}
                            </span>
                        </Col>
                        <Col xs={12} md={6} lg={3} style={{ paddingTop: '10px' }}>
                            <Row>
                                <Col>
                                    <Button style={{
                                        float: 'right', width: '100%', marginBottom: '10px',
                                        backgroundColor: '#6AAE3E', borderColor: '#6AAE3E'
                                    }} onClick={() => {
                                        setDetailsJobModalID(jobID);
                                        setShowJobDetailsModal(true);
                                    }}>
                                        DETAILS <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </Button>
                                </Col>
                                {type !== 'sub-contractor' && jobPrice !== 0 &&
                                    <Col>

                                        <Button style={{
                                            float: 'right', marginRight: '0px', width: '100%', marginBottom: '10px',
                                            backgroundColor: '#E6893B', borderColor: '#E6893B'
                                        }} onClick={findInvoiceID}>
                                            <FontAwesomeIcon icon={faFileLines} />{' '}
                                            <strong>£{jobPrice}</strong>
                                        </Button>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </>
    );
}

export default SiteClientContract;