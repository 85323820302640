import Dropdown from 'react-bootstrap/Dropdown';

const ApproveSubContractorItem = props => {
    const { addSubContractorToContract, name } = props;
    const subContractorID = props._id;
    return (
        <>
            <Dropdown.Item name={subContractorID} onClick={addSubContractorToContract}>{name}</Dropdown.Item>
        </>
    )
}

export default ApproveSubContractorItem;