import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../../lib/Context';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const EditJobModal = () => {
    const { API_LINK, showEditJobModal, setShowEditJobModal, editJobID, jobs, getUserProfile } = useContext(Context)
    // siteContactName, siteContactNumber
    const [siteContactName, setSiteContactName] = useState('')
    const [siteContactNumber, setSiteContactNumber] = useState('')

    const findJobDetails = useCallback(() => {
        for (let i = 0; i < jobs?.length; i++) {
            if (editJobID === jobs[i]._id) {
                setSiteContactName(jobs[i].siteContactName);
                setSiteContactNumber(jobs[i].siteContactNumber);
            }
        }
    }, [jobs, editJobID])

    useEffect(() => {
        findJobDetails();
    }, [findJobDetails])

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'contact-name':
                setSiteContactName(value);
                break;
            case 'contact-number':
                setSiteContactNumber(value);
                break;
            default:
                break;
        }
    }

    const updateJob = async (event) => {
        event.preventDefault();
        const response = await fetch(API_LINK + '/api/update-job', {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify({ jobID: editJobID, siteContactName, siteContactNumber }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            setShowEditJobModal(false);
            await getUserProfile('edit-job');
        } else {
            alert('Updating job failed');
        }
    }

    return (
        <Modal show={showEditJobModal} onHide={() => { setShowEditJobModal(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Job Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* // siteID, siteName, jobName, jobDuration, jobFrequency, jobDate, jobPrice */}
                    <Form.Group className="mb-3" controlId="contact-name">
                        <Form.Control type="text" name="contact-name" onChange={handleInput} value={siteContactName}
                            placeholder="Contact Name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contact-number">
                        <Form.Control type="text" name="contact-number" onChange={handleInput} value={siteContactNumber}
                            placeholder="Contact Number" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setShowEditJobModal(false) }}>
                    Close
                </Button>
                <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} onClick={updateJob}>
                    Update Job
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditJobModal;