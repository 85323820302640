import React, { Fragment, useContext } from 'react'
import { Context } from '../../../lib/Context';
import Invoices from './ListPagesItems/Invoices';
import Container from 'react-bootstrap/Container';



const ListInvoices = () => {
    const { clients, sites, selectClientID } = useContext(Context);

    return (
        <Container>
            {clients?.map((client, index) => (
                selectClientID === '' ?
                    <Fragment key={client._id + index}>
                        {sites?.map((site, index) => (
                            client._id === site.clientID &&
                            <Fragment key={site + index}>
                                <p><strong>{client.siteName}</strong> ({site.siteName} - {site.name} {site.contactNumber})</p>
                                <Invoices siteID={site._id} />
                            </Fragment>
                        ))}
                    </Fragment>
                    :
                    selectClientID === client._id &&
                    <Fragment key={client._id + index}>
                        {sites?.map((site, index) => (
                            client._id === site.clientID &&
                            <Fragment key={site + index}>
                                <p><strong>{client.siteName}</strong> ({site.siteName} - {site.name} {site.contactNumber})</p>
                                <Invoices siteID={site._id} />
                            </Fragment>
                        ))}
                    </Fragment>
            ))}
        </Container>


    )
}

export default ListInvoices