import React, { useContext } from 'react'
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EngineeringIcon from '@mui/icons-material/Engineering';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import MailIcon from '@mui/icons-material/Mail';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import QuizIcon from '@mui/icons-material/Quiz';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Context } from '../../../lib/Context';

const SideNavItems = (props) => {
    const { handleSwitchPage, infoRetrieved, handelLoggedOutDisplayPage } = props;
    const { setShowAddClientModal, setShowAddSubContractorModal, setShowContactModal, setShowEditProfileModal,
        handleDisplayState } = useContext(Context)
    const { userInfo } = useContext(Context)
    // eslint-disable-next-line
    const email = userInfo?.email;
    // eslint-disable-next-line
    const type = userInfo?.type;
    return (
        <>
            {email && type === 'admin' &&
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleSwitchPage('list-clients') }}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Clients'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { setShowAddClientModal(true) }}>
                                <ListItemIcon>
                                    <PersonAddIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Add Client'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleSwitchPage('list-sub-contractors') }}>
                                <ListItemIcon>
                                    <EngineeringIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Sub-Contractors'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { setShowAddSubContractorModal(true) }}>
                                <ListItemIcon>
                                    <GroupAddIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Add Sub-Contractor'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </>
            }
            {email && (type === 'admin' || type === 'client' || type === 'site') &&
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleSwitchPage('live-contracts') }}>
                                <ListItemIcon>
                                    <ContentPasteIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Live Contracts'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleSwitchPage('pending-contracts') }}>
                                <ListItemIcon>
                                    <PendingActionsIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Pending Contracts'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </>
            }
            {email && (type === 'admin' || type === 'client' || type === 'site' || type === 'sub-contractor') &&
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleSwitchPage('jobs') }}>
                                <ListItemIcon>
                                    <WorkHistoryIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Job Planner'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </>
            }
            {email && (type === 'admin' || type === 'client' || type === 'site') &&
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleSwitchPage('invoices') }}>
                                <ListItemIcon>
                                    <ReceiptIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Invoices'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </>
            }
            {email && type === 'sub-contractor' &&
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handleDisplayState('completed') }}>
                                <ListItemIcon>
                                    <ReceiptIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Past Jobs'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </>
            }
            {email && (type === 'client' || type === 'site' || type === 'sub-contractor') &&
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { setShowContactModal(true) }}>
                                <ListItemIcon>
                                    <MailIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Contact Us'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </>
            }
            {email && type === 'admin' &&
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { setShowEditProfileModal(true) }}>
                                <ListItemIcon>
                                    <ManageAccountsIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Profile Settings'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </>
            }
            {infoRetrieved && !email &&
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handelLoggedOutDisplayPage('home-page') }}>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Home'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handelLoggedOutDisplayPage('about-us') }}>
                                <ListItemIcon>
                                    <CleaningServicesIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Services'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { handelLoggedOutDisplayPage('how-it-works') }}>
                                <ListItemIcon>
                                    <QuizIcon />
                                </ListItemIcon>
                                <ListItemText primary={'How it Works'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { setShowContactModal(true) }}>
                                <ListItemIcon>
                                    <MailIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Contact Us'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </>
            }
        </>
    )
}

export default SideNavItems