import { useContext } from 'react';
import JobAdmin from './JobAdmin';
import JobContract from './JobContract';
import { Context } from '../../../../lib/Context';
import JobSubContractor from './JobSubContractor';
import JobSiteClient from './JobSiteClient';
import JobSubContractorContract from './JobSubContractorContract';
import SiteClientContract from './SiteClientContract';

const JobView = props => {
    const { dateDue, completed, viewDate, dateAttended, viewSubContractorID, subContractorID } = props; // From job object
    let { jobDisplayState, userInfo } = useContext(Context);
    let jobDueDate = new Date(dateDue);
    const type = userInfo?.type;

    const getWeekEnd = (days) => {
        let todaysDate = new Date();
        let endOfPeriod = new Date(todaysDate);
        return endOfPeriod.setDate(endOfPeriod.getDate() + days);
    }

    const findJobsOnDay = () => {
        jobDueDate.setHours(10)
        viewDate.setHours(10)
        let dueDay, dueMonth, dueYear;
        if (!completed) {
            dueDay = jobDueDate.getDate();
            dueMonth = jobDueDate.getMonth() + 1;
            dueYear = jobDueDate.getFullYear();
        } else {
            let attendedDate = new Date(dateAttended);
            dueDay = attendedDate.getDate();
            dueMonth = attendedDate.getMonth() + 1;
            dueYear = attendedDate.getFullYear();
        }
        let viewDay = viewDate.getDate();
        let viewMonth = viewDate.getMonth() + 1;
        let viewYear = viewDate.getFullYear();
        return dueDay === viewDay && dueMonth === viewMonth && dueYear === viewYear;

    }

    return (
        <>
            {jobDisplayState === 'this-week' ?
                jobDueDate < getWeekEnd(7) &&
                !completed && (
                    type === 'admin' ? (
                        viewSubContractorID === 'All Sub Contractors' ?
                            <JobAdmin {...props} />
                            :
                            viewSubContractorID === subContractorID &&
                            <JobAdmin {...props} />
                    )
                        : type === 'sub-contractor' ? <JobSubContractor {...props} />
                            : (type === 'client' || type === 'site') && <JobSiteClient {...props} />
                )
                :
                jobDisplayState === 'this-month' ?
                    jobDueDate < getWeekEnd(30) &&
                    !completed && (
                        type === 'admin' ? (
                            viewSubContractorID === 'All Sub Contractors' ?
                                <JobAdmin {...props} />
                                :
                                viewSubContractorID === subContractorID &&
                                <JobAdmin {...props} />
                        )
                            : type === 'sub-contractor' ? <JobSubContractor {...props} />
                                : (type === 'client' || type === 'site') && <JobSiteClient {...props} />
                    )
                    :
                    jobDisplayState === 'this-year' ?
                        jobDueDate < getWeekEnd(365) &&
                        !completed && (
                            type === 'admin' ? (
                                viewSubContractorID === 'All Sub Contractors' ?
                                    <JobAdmin {...props} />
                                    :
                                    viewSubContractorID === subContractorID &&
                                    <JobAdmin {...props} />
                            )
                                : type === 'sub-contractor' ? <JobSubContractor {...props} />
                                    : (type === 'client' || type === 'site') && <JobSiteClient {...props} />
                        )
                        :
                        jobDisplayState === 'all-time' ?
                            !completed && (
                                type === 'admin' ? (
                                    viewSubContractorID === 'All Sub Contractors' ?
                                        <JobAdmin {...props} />
                                        :
                                        viewSubContractorID === subContractorID &&
                                        <JobAdmin {...props} />
                                )
                                    : type === 'sub-contractor' ? <JobSubContractor {...props} />
                                        : (type === 'client' || type === 'site') && <JobSiteClient {...props} />
                            )
                            :
                            jobDisplayState === 'day-view' && findJobsOnDay() && !completed ? (
                                type === 'admin' ? (
                                    viewSubContractorID === 'All Sub Contractors' ?
                                        <JobAdmin {...props} />
                                        :
                                        viewSubContractorID === subContractorID &&
                                        <JobAdmin {...props} />
                                )
                                    : type === 'sub-contractor' ? <JobSubContractor {...props} />
                                        : (type === 'client' || type === 'site') && <JobSiteClient {...props} />
                            )
                                :
                                jobDisplayState === 'completed' && findJobsOnDay() && completed ? (
                                    type === 'admin' ? (
                                        viewSubContractorID === 'All Sub Contractors' ?
                                            <JobContract {...props} />
                                            :
                                            viewSubContractorID === subContractorID &&
                                            <JobContract {...props} />
                                    )
                                        : type === 'sub-contractor' ? <JobSubContractorContract {...props} />
                                            : <SiteClientContract {...props} />
                                )
                                    :
                                    jobDisplayState === 'all-completed' && completed && (
                                        type === 'admin' ? (
                                            viewSubContractorID === 'All Sub Contractors' ?
                                                <JobContract {...props} />
                                                :
                                                viewSubContractorID === subContractorID &&
                                                <JobContract {...props} />
                                        )
                                            : type === 'sub-contractor' ? <JobSubContractorContract {...props} />
                                                : <SiteClientContract {...props} />
                                    )

            }
        </>
    )
}
export default JobView;