import React from 'react'
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

const NewServicesList = (props) => {
    // siteID, siteName, jobDay, jobDescription, siteContactName, siteContactNumber, sitePostcode, siteAddress, siteCity, siteCountry,
    const { index, jobName, jobFrequency, jobPrice, // jobDuration,
        removeServiceFromList } = props;
    return (
        <Row>
            <Col xs={5}>
                <p>{jobName}{jobPrice > 0 && <><br />£{jobPrice}</>}</p>
            </Col>
            <Col xs={5}>
                <p>{jobFrequency}</p>
            </Col>
            <Col xs={2} style={{ textAlign: 'right' }}>
                <Button onClick={() => { removeServiceFromList(index) }} variant="danger" size="sm">
                    X
                </Button>
            </Col>
        </Row>
    )
}

export default NewServicesList