import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import { useContext } from 'react';
import { Context } from '../../../../lib/Context';


const SubContractor = props => {
    // vehicleReg, DOB
    const { setShowEditSubContractorModal, setEditSubContractorID } = useContext(Context)
    const { name, email, number, address, postcode, verificationCode } = props
    const subContractorID = props._id;
    return (
        <Card style={{ float: 'left', marginRight: '0.5%', marginLeft: '0.5%', width: '100%', marginTop: '10px' }}>
            <Card.Body>
                <Card.Title>

                    <Button onClick={() => {
                        setEditSubContractorID(subContractorID);
                        setShowEditSubContractorModal(true);
                    }} style={{ float: 'right' }} size="sm" variant='secondary'>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </Button>
                    {name}
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{email}</Card.Subtitle>
                <Card.Text>
                    {address}<br />{postcode}<br /><strong>{number}</strong><br />
                    {verificationCode?.length > 0 ? `Code: ${verificationCode}` : ''}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default SubContractor;