import { forwardRef, useContext, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ApproveSubContractorItem from '../DropDownItems/ApproveSubContractorItem';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import DatePicker from 'react-datepicker';
import loading from '../../lib/loading-icon.gif';

const ApproveContractModal = () => {
    const [approvingContract, setApprovingContract] = useState(false);
    const { API_LINK, showApproveJobModal, approveContractID, setContracts, setJobs,
        setShowApproveJobModal, subContractors } = useContext(Context);
    const [startDate, setStartDate] = useState(new Date());
    const [subContractorID, setSubContractorID] = useState('');
    const [subContractorName, setSubContractorName] = useState('Tony Lewin');
    const contractID = approveContractID;

    const approveContract = () => {
        // Approve contract
        setApprovingContract(true);
        fetch(API_LINK + '/api/approve-contract', {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify({
                contractID, startDate, subContractorID
            }),
            headers: { 'Content-Type': 'application/json' }
        }).then(async () => {
            fetch(API_LINK + '/api/profile', {
                credentials: 'include',
                method: 'GET'
            }).then(response => {
                response.json().then(data => {
                    setContracts(data.contracts);
                    setJobs(data.jobs);
                    setApprovingContract(false);
                    setShowApproveJobModal(false);
                })
            })
        })
    }

    const addSubContractorToContract = (event) => {
        const subID = event.target.name;
        setSubContractorID(subID);
        for (let i = 0; i < subContractors?.length; i++) {
            if (subContractors[i]._id === subID) {
                setSubContractorName(subContractors[i].name);
            }
        }
    }

    const DateButton = forwardRef(({ value, onClick }, ref) => (
        <Button variant="secondary" onClick={onClick} ref={ref}>
            {value}
        </Button>
    ));

    return (
        <Modal show={showApproveJobModal} onHide={() => { setShowApproveJobModal(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Approve Contract</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {approvingContract ? <img className="loading-image" style={{ width: '100%', height: 'auto' }} src={loading} alt="Loading" /> :
                    <>
                        <div style={{ marginBottom: '20px' }}>
                            Please set your desired <u>sub-contractor</u> and a <u>start date</u> for this contract. You will{' '}
                            not be able to change the date once the contract has been approved.
                        </div>
                        <div style={{ float: 'right' }}>
                            <strong>Start date:</strong> <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                customInput={<DateButton />}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <Dropdown style={{ float: 'left', marginRight: '5px' }}>
                            <Dropdown.Toggle style={{ backgroundColor: '#6AAE3E', borderColor: '#6AAE3E', minWidth: '200px' }} id="dropdown-basic">
                                {subContractorName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {subContractors?.length > 0 && subContractors.map(subContractor => (
                                    <ApproveSubContractorItem {...subContractor} key={subContractor._id} addSubContractorToContract={addSubContractorToContract} />
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </>

                }
            </Modal.Body>
            <Modal.Footer>
                {approvingContract ?
                    <Button style={{ minWidth: '100%', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                        onClick={approveContract}>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </Button>
                    :
                    <Button style={{ minWidth: '100%', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                        onClick={approveContract}>
                        APPROVE CONTRACT
                    </Button>
                }

            </Modal.Footer>
        </Modal>
    )
}

export default ApproveContractModal;