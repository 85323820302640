import { useContext, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import loading from '../../../lib/loading-icon.gif'
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Context } from '../../../lib/Context';
import NewServicesList from '../../Pages/ListPages/ListPagesItems/NewServicesList';


const AddContractModal = () => {
    const { API_LINK, addContractSiteID, addContractSiteName,
        addContractSiteContactName, addContractSiteContactNumber, addContractSitePostcode, setShowListSitesModal,
        addContractSiteAddress, getUserProfile, addingContract, setAddingContract, setShowAddContractModal,
        addSiteCity, addSiteCountry } = useContext(Context)
    const { showAddContractModal } = useContext(Context);
    const [jobName, setJobName] = useState('');
    const [jobDuration, setJobDuration] = useState('Contract Duration');
    const [jobTime, setJobTime] = useState('Time on Site');
    const [jobFrequency, setJobFrequency] = useState('Job Frequency');
    const [jobPrice, setJobPrice] = useState('');
    const [jobCost, setJobCost] = useState('');
    const [jobDescription, setJobDescription] = useState('No comments');
    const [newServices, setNewServices] = useState([]);
    const [monthlyFee, setMonthlyFee] = useState('');
    const [contractInfo, setContractInfo] = useState({});
    const [showAddNewService, setShowAddNewService] = useState(false);
    const [payingMonthly, setPayingMonthly] = useState(true);
    const siteID = addContractSiteID;
    const siteName = addContractSiteName;
    const siteContactName = addContractSiteContactName;
    const siteContactNumber = addContractSiteContactNumber;
    const sitePostcode = addContractSitePostcode;
    const siteAddress = addContractSiteAddress;
    const siteCity = addSiteCity;
    const siteCountry = addSiteCountry

    useEffect(() => {
        setContractInfo({
            siteID, siteName, siteContactName, siteContactNumber, sitePostcode, siteAddress, siteCity, siteCountry, jobDuration
        });
    }, [setContractInfo, siteID, siteName, siteContactName, siteContactNumber, sitePostcode, siteAddress, siteCity, siteCountry, jobDuration])


    const addContract = async (event) => {
        setAddingContract(true);
        event.preventDefault();
        const response = await fetch(API_LINK + '/api/add-contract', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                contractInfo, newServices, monthlyFee
            }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            setShowAddContractModal(false);
            setJobName('')
            setJobDuration('Contract Duration')
            setJobFrequency('Job Frequency')
            setJobTime('Time on Site')
            setJobPrice('')
            setJobDescription('No comments');
            await getUserProfile('add-contract');
            setAddingContract(false);
            setNewServices([]);
            setMonthlyFee('');
            setPayingMonthly(true);
        } else {
            alert('Registration failed');
        }
    }

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'job-name':
                setJobName(value);
                break;
            case 'job-duration':
                setJobDuration(value);
                break;
            case 'job-frequency':
                setJobFrequency(value);
                break;
            case 'job-price':
                setJobPrice(value);
                break;
            case 'job-cost':
                setJobCost(value);
                break;
            case 'job-description':
                setJobDescription(value);
                break;
            case 'monthly-price':
                setMonthlyFee(value);
                break;
            default:
                break;
        }
    }

    const setPaymentType = (value) => {
        if (!value) {
            setMonthlyFee('')
        }
        setPayingMonthly(value)
    }

    const addServiceToList = () => {
        let newServicesList = newServices
        newServices.push({
            jobName, jobFrequency, jobPrice: (payingMonthly ? 0 : jobPrice), jobDescription, jobCost, jobTime
        })
        setNewServices(newServicesList);
        setJobFrequency('Job Frequency')
        setShowAddNewService(false);
    }

    const removeServiceFromList = (index) => {
        let newServicesList = [...newServices]
        newServicesList.splice(index, 1)
        setNewServices([])
        setNewServices(newServicesList)
    }

    return (
        <Modal show={showAddContractModal} onHide={() => {
            setShowAddContractModal(false)
            setShowListSitesModal(true);
            setMonthlyFee('');
            setPayingMonthly(true);
            setJobDuration('Contract Duration');
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Contract</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {!showAddNewService &&
                        <>
                            <Row style={{ marginBottom: '20px' }}>
                                <Col>
                                    <Dropdown style={{ marginRight: '0px' }}>
                                        <Dropdown.Toggle variant="outline-secondary" id="select-duration" style={{
                                            width: '100%'
                                        }}>
                                            {jobDuration}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => {
                                                setJobDuration('One Off');
                                                setPaymentType(false);
                                            }}>One Off</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobDuration('3 Months') }}>3 Months</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobDuration('6 Months') }}>6 Months</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobDuration('12 Months') }}>12 Months</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobDuration('2 Years') }}>2 Years</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobDuration('3 Years') }}>3 Years</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobDuration('4 Years') }}>4 Years</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobDuration('5 Years') }}>5 Years</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                {jobDuration !== 'One Off' &&
                                    <Col>
                                        <Dropdown style={{ marginRight: '0px' }}>
                                            <Dropdown.Toggle variant="outline-secondary" id="select-payment" style={{ width: '100%' }}>
                                                {payingMonthly ? 'Pay Monthly' : 'Pay Per Job'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {
                                                    setPaymentType(true)
                                                    setNewServices([])
                                                }}>Pay Monthly</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    setPaymentType(false)
                                                    setNewServices([])
                                                }}>Pay Per Job</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                }
                            </Row>
                            {payingMonthly && jobDuration !== 'One Off' &&
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="monthly-price">
                                            <Form.Control type="text" name="monthly-price" value={monthlyFee}
                                                onChange={handleInput} placeholder="Monthly Price" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                        </>
                    }
                </Form>
                {addingContract ?
                    <img className="loading-image" src={loading} style={{ width: '100%', height: 'auto' }} alt="Loading" />
                    :
                    showAddNewService ?
                        <Form>
                            {/* // siteID, siteName, jobName, jobDuration, jobFrequency, jobDate, jobPrice */}
                            <Form.Group className="mb-3" controlId="job-name">
                                <Form.Control type="text" name="job-name" onChange={handleInput} placeholder="Job Name i.e. 'Windows'" />
                            </Form.Group>
                            <Row>
                                {!payingMonthly &&
                                    <Col>
                                        <Form.Group className="mb-3" controlId="job-price">
                                            <Form.Control type="text" name="job-price" onChange={handleInput} placeholder="Job Price (Customer)" />
                                        </Form.Group>
                                    </Col>
                                }
                                <Col>
                                    <Form.Group className="mb-3" controlId="job-cost">
                                        <Form.Control type="text" name="job-cost" onChange={handleInput} placeholder="Job Cost (Sub Contractor)" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="job-description">
                                        <Form.Control
                                            as="textarea"
                                            name="job-description"
                                            onChange={handleInput}
                                            placeholder="Comments on the job..."
                                            style={{ height: '100px' }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: '20px', width: '100%' }}>
                                    <Dropdown style={{ marginRight: '0px' }}>
                                        <Dropdown.Toggle variant="outline-secondary" id="select-time" style={{ width: '100%' }}>
                                            {jobTime}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ width: '100%' }}>
                                            <Dropdown.Item onClick={() => { setJobTime('30 Mins') }}>30 Mins</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobTime('1 Hour') }}>1 Hour</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobTime('1.5 Hours') }}>1.5 Hours</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobTime('2 Hours') }}>2 Hours</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobTime('3 Hours') }}>3 Hours</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobTime('4 Hours') }}>4 Hours</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobTime('5 Hours') }}>5 Hours</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobTime('6 Hours') }}>6 Hours</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobTime('7 Hours') }}>7 Hours</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobTime('8 Hours') }}>8 Hours</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                {jobDuration !== 'One Off' &&
                                    <Col>
                                        <Dropdown style={{ display: 'block' }}>
                                            <Dropdown.Toggle variant="outline-secondary" id="select-frequency"
                                                style={{ width: '100%', float: 'right' }}>
                                                {jobFrequency}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ width: '100%' }}>
                                                <Dropdown.Item onClick={() => { setJobFrequency('Weekly') }}>Weekly</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setJobFrequency('Fortnightly') }}>Fortnightly</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setJobFrequency('Monthly') }}>Monthly</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setJobFrequency('8 Weekly') }}>8 Weekly</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setJobFrequency('Biannually') }}>Biannually</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setJobFrequency('Triannually') }}>Triannually</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setJobFrequency('Quarterly') }}>Quarterly</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setJobFrequency('Annually') }}>Annually</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                }
                            </Row>
                        </Form>
                        :
                        newServices.length > 0 ?
                            newServices.map((service, index) => (
                                <NewServicesList key={index} {...service} index={index} removeServiceFromList={removeServiceFromList} />
                            ))
                            :
                            'Please add a new service to create the contratct'
                }

            </Modal.Body>
            <Modal.Footer>
                {jobDuration === 'Contract Duration' &&
                    <Row style={{ width: '100%' }}>
                        <Col xs={12}>
                            <Alert variant={'danger'}>
                                Please select a duration for the contract.
                            </Alert>
                        </Col>
                    </Row>
                }
                <Row style={{ width: '100%' }}>

                    {!showAddNewService && jobDuration !== 'Contract Duration' ?
                        (
                            <Col style={{ textAlign: 'left' }}>
                                <Button style={{ width: '100%', float: 'left', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                                    onClick={() => { setShowAddNewService(!showAddNewService) }}>
                                    Add Service
                                </Button>
                            </Col>
                        )
                        : jobDuration !== 'Contract Duration' &&
                        <Col style={{ textAlign: 'left' }}>
                            <Button style={{ width: '100%', float: 'left' }}
                                onClick={() => {
                                    setShowAddNewService(!showAddNewService)
                                    setJobFrequency('Job Frequency')
                                }} variant="secondary">
                                Cancel
                            </Button>
                        </Col>
                    }



                    {addingContract ?
                        <Col>
                            <Button style={{ minWidth: '170px', float: 'right', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                                onClick={addContract} disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            </Button>
                        </Col>
                        : !showAddNewService && newServices.length > 0 ?
                            <Col>
                                <Button style={{ minWidth: '170px', float: 'right', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                                    onClick={addContract}>
                                    Add Contract
                                </Button>
                            </Col>
                            : showAddNewService &&
                            (jobFrequency !== 'Job Frequency' || jobDuration === 'One Off') &&
                            <Col>
                                <Button style={{ minWidth: '170px', float: 'right', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                                    onClick={addServiceToList}>
                                    Save Service
                                </Button>
                            </Col>

                    }
                </Row>

            </Modal.Footer>
        </Modal>
    )
}

export default AddContractModal;