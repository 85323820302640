import { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import '../../../css/ListClients.css'
import { Context } from '../../../lib/Context';

const ListClients = (props) => {
    const { setShowListSitesModal, setListSitesClientID, setShowEditClientModal, setEditClientID } = useContext(Context);
    const { siteName, name, contactNumber, siteAddress, sitePostcode, email, verificationCode } = props;
    const clientID = props._id;

    return (
        <Card style={{ float: 'left', marginRight: '0.5%', marginLeft: '0.5%', width: '100%', marginTop: '10px' }}>
            <Card.Body>
                <Card.Title>
                    <Button style={{ float: 'right' }} onClick={async () => {
                        setEditClientID(clientID);
                        setShowEditClientModal(true);
                    }} size="sm" variant='secondary'>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </Button>
                    {siteName}s
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{email}</Card.Subtitle>
                <Card.Text>
                    {name} {verificationCode?.length > 0 && '(Code: ' + verificationCode + ')'}<br />{siteAddress}<br />{sitePostcode}<br /><strong>{contactNumber}</strong>

                </Card.Text>
                <Button style={{ marginRight: '15px', marginBottom: '5px', minWidth: '100%', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                    onClick={() => {
                        setListSitesClientID(clientID);
                        setShowListSitesModal(true);
                    }}>View Sites <FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
            </Card.Body>
        </Card>
    )
}

export default ListClients;