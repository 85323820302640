import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Dropdown, Navbar, Row } from 'react-bootstrap'
import { Context } from '../../lib/Context'

const ListInvoiceNav = () => {
    const { clients, setSelectClientID, selectClientID } = useContext(Context);
    const [clientName, setClientName] = useState('')

    useEffect(() => {
        for (let i = 0; i < clients.length; i++) {
            if (selectClientID === clients[i]._id) {
                setClientName(clients[i].siteName);
            }
        }
        if (selectClientID === '') {
            setClientName('All Clients')
        }
    }, [clients, selectClientID])



    return (
        <Navbar className="bg-body-tertiary" style={{ marginBottom: '20px', marginTop: '60px' }}>
            <Container>
                <Navbar.Text style={{ width: '100%' }}>
                    <Row>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle style={{ width: '100%' }} variant="success" id="dropdown-basic">
                                    {clientName}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '100%' }}>
                                    <Dropdown.Item onClick={() => { setSelectClientID('') }}>
                                        All Clients
                                    </Dropdown.Item>
                                    {clients?.length > 0 &&
                                        clients.map((client, index) => (
                                            <Dropdown.Item key={'select-client' + client._id + index} onClick={() => { setSelectClientID(client._id) }}>
                                                {client.siteName}
                                            </Dropdown.Item>
                                        ))
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Navbar.Text>
            </Container>
        </Navbar>
    )
}

export default ListInvoiceNav