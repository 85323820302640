import Form from 'react-bootstrap/Form';

const RegisterForm = (props) => {
    const { buildEmail, buildPassword, emailIsValid } = props;

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Please enter in your email address{emailIsValid && ' and password'}.</Form.Label>
                <Form.Control type="email" placeholder="Enter email" onChange={buildEmail} />
            </Form.Group>
            {emailIsValid &&
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control type="password" placeholder="Password" onChange={buildPassword} />
                </Form.Group>
            }
        </Form>
    )
}

export default RegisterForm;