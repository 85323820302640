import { useContext, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Context } from '../../../lib/Context';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import loading from '../../../lib/loading-icon.gif';

const AddClientModal = () => {
    const { getUserProfile, API_LINK, showAddClientModal, setShowAddClientModal,
        clients, sites, subContractors } = useContext(Context)
    const [siteEmail, setSiteEmail] = useState('');
    const [siteName, setSiteName] = useState('');
    const [siteAddress, setSiteAddress] = useState('');
    const [sitePostcode, setSitePostcode] = useState('');
    const [siteCity, setSiteCity] = useState('');
    const [siteCountry, setSiteCountry] = useState('')
    const [name, setName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [isCommercialClient, setIsCommercialClient] = useState(false);
    const [addingClient, setAddingClient] = useState(false);

    const registerClient = async (event) => {
        setAddingClient(true);
        event.preventDefault();
        const response = await fetch(API_LINK + '/api/register-client', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                email: siteEmail, siteName, siteAddress, sitePostcode, name,
                contactNumber, isCommercialClient, siteCity, siteCountry
            }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            setShowAddClientModal(false);
            setSiteEmail('')
            setSiteName('')
            setSiteAddress('')
            setSitePostcode('')
            setName('')
            setContactNumber('')
            setSiteCity('')
            setSiteCountry('')
            setIsCommercialClient(false);
            setAddingClient(false);
            await getUserProfile();
        } else {
            alert('Registration failed');
        }

    }

    const submitHandler = (event) => {
        let newUserValid = true;
        for (let i = 0; i < clients.length; i++) {
            if (clients[i].email === siteEmail) {
                newUserValid = false;
                setErrorMessage('There is already a client with that email address.')
            }
        }
        if (newUserValid) {
            for (let i = 0; i < sites.length; i++) {
                if (sites[i].email === siteEmail) {
                    newUserValid = false;
                    setErrorMessage('There is already a site with that email address.')
                }
            }
            for (let i = 0; i < subContractors.length; i++) {
                if (subContractors[i].email === siteEmail) {
                    newUserValid = false;
                    setErrorMessage('There is already a sub-contractor with that email address.')
                }
            }
        }
        if (newUserValid) {
            registerClient(event);
        }
    }

    const processInput = (event) => {
        setErrorMessage('');
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'site-contact-name':
                setName(value);
                break;
            case 'site-phone':
                setContactNumber(value);
                break;
            case 'site-email':
                setSiteEmail(value);
                break;
            case 'site-name':
                setSiteName(value);
                break;
            case 'site-address':
                setSiteAddress(value);
                break;
            case 'site-postcode':
                setSitePostcode(value);
                break;
            case 'site-city':
                setSiteCity(value);
                break;
            case 'site-country':
                setSiteCountry(value);
                break;
            case 'is-commercial':
                setIsCommercialClient(!isCommercialClient);
                break;
            default:
                break;
        }
    }

    const resetModal = () => {
        setErrorMessage('');
        setShowAddClientModal(false);
        setSiteEmail('');
        setSiteName('');
        setSiteAddress('');
        setSitePostcode('');
        setName('');
        setContactNumber('');
        setSiteCity('');
        setSiteCountry('');
        setIsCommercialClient(false);
        setAddingClient(false);
    }

    return (
        <Modal show={showAddClientModal} onHide={() => {
            resetModal();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Client</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {addingClient ?
                    <img className="loading-image" src={loading} style={{ width: '100%', height: 'auto' }} alt="Loading" />
                    :
                    <>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Check style={{ marginBottom: '15px', marginTop: '5px' }} type={'checkbox'}
                                        name={'is-commercial'} value={isCommercialClient} onInput={processInput}
                                        label={isCommercialClient ? 'Commercial client' : 'Check box for commercial clients'} />
                                </Col>
                                {isCommercialClient &&
                                    <Col>
                                        <Form.Group className="mb-3" controlId="site-name">
                                            <Form.Control type="text" name="site-name" onChange={processInput} placeholder="Company Name" />
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-address">
                                        <Form.Control type="text" name="site-address" onChange={processInput} placeholder="Address" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-contact-name">
                                        <Form.Control type="text" name="site-contact-name" onChange={processInput} placeholder="Contact Name" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-postcode">
                                        <Form.Control type="text" name="site-postcode" onChange={processInput} placeholder="Postcode" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-phone">
                                        <Form.Control type="text" name="site-phone" onChange={processInput} placeholder="Phone Number" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-city">
                                        <Form.Control type="text" name="site-city" onChange={processInput} placeholder="City" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-email">
                                        <Form.Control type="email" name="site-email" onChange={processInput}
                                            placeholder={isCommercialClient ? "Company Email" : "Email"} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-country">
                                        <Form.Control type="text" name="site-country" onChange={processInput} placeholder="Country" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <i>
                                {isCommercialClient ?
                                    <>
                                        <Alert style={{ marginTop: '20px' }} variant={'danger'}>
                                            <strong>NOTE:</strong> <u>The client you are registering now won't be emailed</u>.{' '}
                                            Only sites receive emails. If you want this client to receive a welcome email then{' '}
                                            you will need to create a site using the same email address once the client has been{' '}
                                            created.
                                        </Alert>

                                    </>
                                    :
                                    <>
                                        <Alert style={{ marginTop: '20px' }} variant={'danger'}>
                                            <strong>NOTE:</strong> When you create this new client, a new site will be{' '}
                                            automatially created with the same contact information and a <u>welcome email{' '}
                                                will be sent to the user</u>.
                                        </Alert>
                                    </>
                                }
                            </i>

                        </Form>
                    </>
                }
                {errorMessage &&
                    <Alert style={{ textAlign: 'center', marginTop: '20px' }} variant={'danger'}>
                        {errorMessage}
                    </Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                {addingClient ?
                    <Button style={{ minWidth: '170px', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                        onClick={submitHandler} disabled>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </Button>
                    :
                    <Button style={{ minWidth: '170px', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                        onClick={submitHandler}>
                        Add Client
                    </Button>}

            </Modal.Footer>
        </Modal >
    )
}

export default AddClientModal;