import { useContext } from "react";

import SubContractor from "./ListPagesItems/SubContractor";
import { Context } from "../../../lib/Context";
import { Col, Row } from "react-bootstrap";

const ListSubContractors = () => {
    const { subContractors } = useContext(Context);
    return (
        <>
            <Row style={{ marginTop: '60px' }}>
                {subContractors?.length > 0 ? subContractors.map(subContractor => (
                    <Col xs={12} md={6} xl={4}>
                        <SubContractor {...subContractor} key={subContractor._id} />
                    </Col>
                ))
                    :
                    'There are no sub-contractors registered yet.'
                }
            </Row>
        </>
    )
}

export default ListSubContractors;