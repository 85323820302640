import { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import RegisterForm from '../../Elements/RegisterForm';
import LoginForm from '../../Elements/LoginForm';
import validateEmail from '../../lib/Functions/validateEmail';
import { Context } from '../../lib/Context';
import LoginButtons from '../LoginButtons';
import ForgotPasswordForm from '../ForgotPasswordForm';

const AuthModal = (props) => {
  const { showLoginModal, emailIsValid, setEmailIsValid, getUserProfile } = props;
  const { API_LINK, setUserInfo, setViewPage, setShowLoginModal, setUserType, userType,
    setShowValidateEmailModal } = useContext(Context)
  const [isLogin, setIsLogin] = useState(true); // True when in login mode.
  const [email, setEmail] = useState(''); // Collects the users email address
  const [password, setPassword] = useState(''); // Collects users password
  const [showLoginError, setShowLoginError] = useState(false);
  const [userHasPassword, setUserHasPassword] = useState(false);
  const [userDataRetrieved, setUserDataRetrieved] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [hasPasswordResetCode, setHasPasswordResetCode] = useState(false); // If a password reset code is present then true else false.
  const [securityCode, setSecurityCode] = useState('') // This is for password reset.
  const [securityCodeValid, setSecurityCodeValid] = useState(true); // This is for password reset.
  const [emailVerified, setEmailVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const userLogin = async (event) => {
    event.preventDefault();
    const response = await fetch(API_LINK + '/api/login', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
    if (response.ok) {
      response.json().then(async data => {
        const type = data.type
        setUserInfo(data);
        switch (type) {
          case 'admin':
            setViewPage('list-clients')
            break;
          case 'sub-contractor':
            setViewPage('jobs')
            break;
          default:
            setViewPage('jobs')
            break;
        }
        setShowLoginModal(false);
        setEmail('');
        setHasPasswordResetCode(null);
        setShowValidateEmailModal(!data.emailVerified);
        setUserDataRetrieved(false);
        await getUserProfile('login');
      });
    } else {
      setShowLoginError(true);
    }
  }

  const resetUserPassword = () => {
    fetch(API_LINK + `/api/reset-password`, {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify({
        email
      }),
      headers: { 'Content-Type': 'application/json' }
    }).then(async (response) => {
      response.json().then(async data => {
        setHasPasswordResetCode(data.passwordResetCode);
        setIsLogin(true);
        setResetPassword(false);
      });
    })
  }

  const setUserPassword = async (event) => {
    event.preventDefault();
    const response = await fetch(API_LINK + '/api/set-password', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: { 'Content-Type': 'application/json' }
    })
    if (response.status === 200) {
      await userLogin(event);
    } else {
      alert('Registration failed');
    }
  }

  const registerUser = async (event) => {
    event.preventDefault();
    const response = await fetch(API_LINK + '/api/register', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: { 'Content-Type': 'application/json' }
    })
    if (response.status === 200) {
      const response = await fetch(API_LINK + '/api/login', {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      })
      if (response.ok) {
        response.json().then(userInfo => {
          setUserInfo(userInfo);
          setShowLoginModal(false);
        });
      } else {
        alert('Wrong credentials');
      }
    } else {
      alert('Registration failed');
    }
  }

  const checkPasswordResetCode = async () => {
    const response = await fetch(API_LINK + '/api/check-password-code', {
      method: 'POST',
      body: JSON.stringify({ securityCode, email }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
    if (response.ok) {
      response.json().then(async data => {
        setSecurityCodeValid(data.securityCodeValid);
        setIsLogin(true);
        setResetPassword(false);
        setPasswordsMatch(false);
        setUserHasPassword(false);
        await checkUserType(email);
      });

    } else {
      alert("Couldn't validate code");
    }
  }

  const checkVerificationCode = async () => {
    const response = await fetch(API_LINK + '/api/check-verification-code', {
      method: 'POST',
      body: JSON.stringify({ verificationCode, email }),
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    })
    if (response.ok) {
      response.json().then(async data => {
        await checkUserType(email);
      });
    } else {
      alert("Couldn't validate code");
    }
  }

  const checkUserType = async (email) => {
    setUserDataRetrieved(false);
    fetch(API_LINK + '/api/check-user-type/' + email, {
      credentials: 'include',
      method: 'GET'
    }).then(response => {
      response.json().then(data => {
        setSecurityCode('');
        setUserType(data.type);
        setUserHasPassword(data.hasPassword);
        setHasPasswordResetCode(data.hasPasswordResetCode);
        setEmailVerified(data.emailVerified);
        setUserDataRetrieved(true);
      })
    })
  }

  const buildEmail = async (event) => {
    setUserDataRetrieved(false);
    setUserType('');
    setShowLoginError(false);
    const currentText = event.target.value.toLowerCase();
    setEmail(currentText);
    let emailValid = validateEmail(currentText);
    if (emailValid) {
      await checkUserType(currentText);
      handleEmailIsValid(true);
    } else {
      handleEmailIsValid(false);
      setUserHasPassword(false);
      setUserType('');
    }
  }

  const buildPassword = async (event) => {
    setShowLoginError(false);
    setPasswordsMatch(false);
    const currentText = event.target.value;
    setPassword(currentText);
  }

  const handleEmailIsValid = (value) => {
    setEmailIsValid(value);
  }

  return (
    <Modal show={showLoginModal} onHide={() => {
      setEmail('')
      setShowLoginModal(false);
      setUserDataRetrieved(false);
      setResetPassword(false);
    }}>
      <Modal.Header closeButton>
        <Modal.Title>ECS LTD Account {isLogin ? 'Login' : 'Registration'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLogin ?
          !resetPassword ?
            <LoginForm
              handleEmailIsValid={handleEmailIsValid}
              emailIsValid={emailIsValid}
              buildEmail={buildEmail}
              buildPassword={buildPassword}
              userType={userType}
              userDataRetrieved={userDataRetrieved}
              userHasPassword={userHasPassword}
              setPasswordsMatch={setPasswordsMatch}
              password={password}
              setResetPassword={setResetPassword}
              email={email}
              hasPasswordResetCode={hasPasswordResetCode}
              checkPasswordResetCode={checkPasswordResetCode}
              securityCode={securityCode}
              setSecurityCode={setSecurityCode}
              emailVerified={emailVerified}
              setVerificationCode={setVerificationCode}
              verificationCode={verificationCode}
            />
            :
            <ForgotPasswordForm
              checkUserType={checkUserType}
              setResetPassword={setResetPassword}
              email={email}
              setEmail={setEmail}
              setShowLoginError={setShowLoginError}
            />
          :
          <RegisterForm
            handleEmailIsValid={handleEmailIsValid}
            emailIsValid={emailIsValid}
            buildEmail={buildEmail}
            buildPassword={buildPassword}
          />
        }
        {showLoginError &&
          <Alert variant='danger'>
            Sorry you have entered in the wrong information!
          </Alert>
        }
        {!securityCodeValid &&
          <Alert variant='danger'>
            Sorry that code did not match the one in our records!
          </Alert>
        }
      </Modal.Body>
      <Modal.Footer>
        {!emailIsValid &&
          <Alert style={{ width: '100%' }} variant='success'>
            Please enter in your email to continue.
          </Alert>
          // <Button variant="secondary" onClick={toggleLoginMode}>
          //   {isLogin ? 'Registration Form' : 'Login Form'}
          // </Button>
        }
        <LoginButtons
          isLogin={isLogin}
          userDataRetrieved={userDataRetrieved}
          userLogin={userLogin}
          userHasPassword={userHasPassword}
          passwordsMatch={passwordsMatch}
          registerUser={registerUser}
          resetPassword={resetPassword}
          resetUserPassword={resetUserPassword}
          setUserPassword={setUserPassword}
          hasPasswordResetCode={hasPasswordResetCode}
          checkPasswordResetCode={checkPasswordResetCode}
          emailVerified={emailVerified}
          checkVerificationCode={checkVerificationCode}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AuthModal;