import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import ListSites from '../Pages/ListPages/ListSites'
import { Context } from '../../lib/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'

const ListSiteModal = () => {
    const { showListSitesModal, sites, listSitesClientID,
        setAddSiteClientID, setShowAddSiteModal, setShowListSitesModal } = useContext(Context)
    const clientID = listSitesClientID;

    return (
        <Modal show={showListSitesModal} onHide={() => { setShowListSitesModal(false) }} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>View Sites</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion style={{}} >
                    {sites?.length > 0 && sites.map((site, index) => (
                        site.clientID === clientID &&
                        <ListSites key={site._id} {...site} siteIndex={index} />
                    ))
                    }
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    setAddSiteClientID(clientID);
                    setShowListSitesModal(false);
                    setShowAddSiteModal(true);
                }} style={{ minWidth: '100%', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}>
                    Add New Site <FontAwesomeIcon icon={faCirclePlus} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default ListSiteModal;