
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../../css/Job.css'
import {
    faAddressBook,
    faLocationDot, faMoneyBill, faPhone, faStoreAlt, faTruckFast, faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../../../lib/Context';

const JobSiteClient = (props) => {
    const { siteName, jobName, dateDue, siteAddress, sitePostcode, subContractorID } = props;
    const [jobPrice, setJobPrice] = useState(props.jobPrice);
    const { API_LINK } = useContext(Context);
    const [subContractorName, setSubContractorName] = useState('')
    const [subContractorNumber, setSubContractorNumber] = useState('')
    const [infoReceived, setInfoReceived] = useState(false);
    const [isMonthlyContract, setIsMonthlyContract] = useState(false);
    const jobID = props._id;
    const date = new Date(dateDue)
    const day = date.getUTCDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const getSubContractorsName = useCallback(() => {
        fetch(API_LINK + '/api/get-sub-contractor-contact/' + subContractorID, {
            credentials: 'include',
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                setSubContractorName(data.name);
                setSubContractorNumber(data.number);
                setInfoReceived(true);
            })
        })
    }, [API_LINK, setSubContractorName, subContractorID])

    const getJobPrice = useCallback(() => {
        fetch(API_LINK + '/api/get-monthly-price/' + jobID, {
            credentials: 'include',
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                setJobPrice(data.jobPrice);
                setIsMonthlyContract(true);
            })
        })
    }, [API_LINK, jobID])

    useEffect(() => {
        getSubContractorsName();
        if (jobPrice === 0) {
            getJobPrice();
        }
    }, [getSubContractorsName, getJobPrice, jobPrice])

    return (
        <>
            <Card body style={{ marginBottom: '20px' }}>
                <Container fluid>
                    <Row>

                        <Col xs={12} sm={6} lg={3}>
                            <FontAwesomeIcon icon={faStoreAlt} /> {siteName}<br />
                            <FontAwesomeIcon icon={faTruckFast} /> {jobName}
                        </Col>
                        <Col xs={12} sm={6} lg={3}>
                            <FontAwesomeIcon icon={faAddressBook} /> {siteAddress}<br />
                            <FontAwesomeIcon icon={faLocationDot} /> {sitePostcode}
                        </Col>
                        <Col xs={12} sm={6} lg={3}>
                            {!infoReceived ? <Spinner animation="border" /> :
                                <>
                                    <FontAwesomeIcon icon={faUserTie} />{' '} {subContractorName}<br />
                                    <FontAwesomeIcon icon={faPhone} />{' '} <a href={"tel:" + subContractorNumber}>{subContractorNumber}</a>
                                </>
                            }
                        </Col>
                        <Col xs={12} sm={6} lg={3}>
                            <span>
                                <strong>
                                    {day}/{month}/{year}<br />
                                </strong>
                                <FontAwesomeIcon style={{ marginTop: '7px' }} icon={faMoneyBill} />{' '}
                                {isMonthlyContract ? `Monthly (£${jobPrice})` : `£${jobPrice}`}
                            </span>

                        </Col>
                    </Row>
                </Container>
            </Card>

        </>
    );
}

export default JobSiteClient