// eslint-disable-next-line
import logo from '../../lib/Images/ecshome.jpg';
import windowClean from '../../lib/Images/WindowClean.jpg';
import nastyUbend from '../../lib/Images/nastyUbend.jpg';
import moss from '../../lib/Images/Moss.jpg';
import tonyWindow from '../../lib/Images/tonyCleaninghouse_slideshow.jpg';
import pressureWash from '../../lib/Images/outdoorFloor.jpg';
import facia from '../../lib/Images/facia.jpg';
import checkATrade from '../../lib/Images/check-a-trade-rating.jpg';
import checkATradeLogo from '../../lib/Images/check-a-trade-logo.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/Homepage.css';
import '../../css/ServicesPage.css'

import React, { useContext } from 'react';
import { Context } from '../../lib/Context';

const HomePage = () => {
    const { setShowContactModal } = useContext(Context)

    return (
        <div className="home-font">
            <Row style={{ marginTop: '40px', paddingTop: '20px' }}>
                <Col>
                    <img src={windowClean} alt="Website Logo" style={{ maxWidth: '100%' }} />
                </Col>
                <Col lg={'4'}>
                    <h5>Based in Kent, ECS is dedicated to the exterior cleaning maintenance of your property.</h5>
                    <p>
                        Cleaning the exterior increases the value of your property, the visual appeal and with
                        regular cleaning, the materials used in the construction of your home is prolonged by
                        removing the organic organisms that naturally eat away and decay UPVC, cladding, brick
                        tiles and stone.
                    </p>
                    <p>
                        Each service we provide has a different benefit to the property, either increasing the
                        value instantly or saving you money in the long run, you choose the service, we exceed
                        your expectations and you are confident knowing your house is in safe hands.
                    </p>
                    <h4>Why choose ECS Home?</h4>
                    <p>
                        Maintaining and cleaning your home regularly, naturally increase its value by adding to
                        its kerb appeal, new buyers confidence, its functioning parts and the health of your property.
                        The BIGGEST PROBLEM facing home owners today is unnecessarily spending £1000's on repairs
                        that could have been avoided with regular maintenance and cleaning.
                    </p>
                </Col>
            </Row>
            <Row style={{ paddingTop: '50px' }} xs={12}>
                <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                    <h1 style={{ marginBottom: '20px' }}>ATTENTION HOME OWNERS!!!</h1>

                    <h2>5 SERVICES THAT INCREASE VALUE, IMPROVE AESTHETIC AND PROTECT <br />YOUR HOME, SAVING YOU £1000'S IN REPAIRS OVER THE YEARS!</h2>
                </div>
                <Col xs={0} lg={1} />
                <Col xs={6} md={4} lg={2}>
                    <img src={nastyUbend} alt="Gutter Cleaning" style={{ maxWidth: '100%' }} /><br />
                    <div className='service'>GUTTER CLEANING</div>
                    <ul>
                        <li>prevents mold and damp on the inside of your house</li>
                        <li>prevents wood rot on the roof beams</li>
                        <li>protects the foundation of the house</li>
                    </ul>
                </Col>
                <Col xs={6} md={4} lg={2}>
                    <img src={moss} alt="Roof Cleaning" style={{ maxWidth: '100%' }} /><br />
                    <div className='service'>ROOF CLEANING</div>
                    <ul>
                        <li>Increases the value of the property upto 8%</li>
                        <li>increases curb appeal</li>
                        <li>identify loose and broken tiles</li>
                        <li>removes moss that lift and break tiles</li>
                    </ul>
                </Col>
                <Col xs={6} md={4} lg={2}>
                    <img src={tonyWindow} alt="Window Cleaning" style={{ maxWidth: '100%' }} /><br />
                    <div className='service'>WINDOW CLEANING</div>
                    <ul>
                        <li>Improves visability and lets in more light</li>
                        <li>prolongs UPVC and the rubber seals around the glass</li>
                    </ul>
                </Col>
                <Col xs={6} md={6} lg={2}>
                    <img src={pressureWash} alt="Pressure Wash" style={{ maxWidth: '100%' }} /><br />
                    <div className='service'>PRESSURE WASHING</div>
                    <ul>
                        <li>restores the image of the property</li>
                        <li>highlights hidden damage</li>
                        <li>safer walking area</li>
                    </ul>
                </Col>
                <Col xs={6} md={6} lg={2}>
                    <img src={facia} alt="Facia & Soffit" style={{ maxWidth: '100%' }} /><br />
                    <div className='service'>FACIA & SOFFIT CLEAN</div>
                    <ul>
                        <li>protects roof beams from damp</li>
                        <li>protects against mould</li>
                        <li>adds curb appeal</li>
                    </ul>
                </Col>
                <Col xs={0} lg={1} />
            </Row>
            <Row style={{ backgroundColor: 'black', marginTop: '50px', marginBottom: '0px' }}>
                <Col style={{ textAlign: 'center', marginTop: '50px', marginBottom: '50px', color: 'white' }}>
                    <h1>Contact Us</h1>
                    <p style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
                        If you would like to get in touch with us then you can email us by{' '}
                        <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { setShowContactModal(true) }}>
                            clicking here
                        </span>
                        , or you can WhatsApp us{' '}
                        images of the job you would like us to do for you using 07383618032 and we'll get back to you ASAP.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col lg={'8'} style={{ padding: '30px' }}>
                    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <h3>THERE ARE LIMITED SPACES AVAILABLE FOR <br />THIS PACKAGE, BOOK YOUR PLACE NOW!!!</h3>
                    </div>
                    <p>
                        These 5 services are in many cases are over looked but are vital to increasing the value, improving
                        the image and protecting your property, E.C.S ltd have a 9.87 out of 10 rating on CHECKATRADE with
                        many happy returning customers, which is why we have put together a one time incredible offer, with
                        the E.C.S 100% money back guarantee!!!
                    </p>
                    <p>
                        We have put together a 1 year package that includes all 5 services for £55 a month (based on an
                        average 3 bedroom house), all 5 services would usually cost upwards of £1500. But as an introductry
                        offer to E.C.S. you can get all 5 services from £55 pcm and with your booking we will include an extra
                        gutter clean and an extra 6 window cleans to make sure your house is working at its best all year round.
                    </p>
                </Col>
                <Col>
                    <a href="https://www.checkatrade.com/trades/exteriorcleaningservicesltd" target='blank'>
                        <img src={checkATrade} alt="Website Logo" style={{ maxWidth: '100%', marginBottom: '15px', marginTop: '40px' }} />
                        <img src={checkATradeLogo} alt="Website Logo" style={{
                            display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '15px', marginTop: '-30px'
                        }} />
                    </a>
                </Col>
            </Row>
        </div>
    );
};

export default HomePage;