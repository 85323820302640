import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../../lib/Context';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import loading from '../../../lib/loading-icon.gif'
import { Spinner } from 'react-bootstrap';


const EditSiteModal = () => {
    const { API_LINK, showEditSiteModal, setShowEditSiteModal, setShowListSitesModal, sites,
        editSiteID, getUserProfile } = useContext(Context);
    const [email, setEmail] = useState('');
    const [siteName, setSiteName] = useState('');
    const [siteAddress, setSiteAddress] = useState('');
    const [sitePostcode, setSitePostcode] = useState('');
    const [siteCity, setSiteCity] = useState('')
    const [siteCountry, setSiteCountry] = useState('')
    const [name, setName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [updatingSite, setUpdatingSite] = useState(false);

    const findSiteDetails = useCallback(() => {
        for (let i = 0; i < sites?.length; i++) {
            if (editSiteID === sites[i]._id) {
                setEmail(sites[i].email)
                setSiteName(sites[i].siteName);
                setSiteAddress(sites[i].siteAddress);
                setSitePostcode(sites[i].sitePostcode);
                setSiteCity(sites[i].siteCity);
                setSiteCountry(sites[i].siteCountry);
                setName(sites[i].name);
                setContactNumber(sites[i].contactNumber);
            }
        }
    }, [sites, editSiteID])

    useEffect(() => {
        findSiteDetails();
    }, [findSiteDetails])

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'site-name':
                setSiteName(value);
                break;
            case 'site-email':
                setEmail(value);
                break;
            case 'site-address':
                setSiteAddress(value);
                break;
            case 'site-postcode':
                setSitePostcode(value);
                break;
            case 'site-city':
                setSiteCity(value);
                break;
            case 'site-country':
                setSiteCountry(value);
                break;
            case 'contact-name':
                setName(value);
                break;
            case 'contact-number':
                setContactNumber(value);
                break;
            default:
                break;
        }
    }

    const updateSite = async (event) => {
        setUpdatingSite(true);
        event.preventDefault();
        const response = await fetch(API_LINK + '/api/update-site', {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify({
                siteID: editSiteID, email, siteName, siteAddress, sitePostcode,
                siteCity, siteCountry, name, contactNumber
            }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            setShowEditSiteModal(false);
            setUpdatingSite(false);
            await getUserProfile('edit-site');
        } else {
            alert('Updating site failed');
        }
    }

    return (
        <Modal show={showEditSiteModal} onHide={() => {
            setShowEditSiteModal(false);
            setShowListSitesModal(true);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Site Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {updatingSite ? <img className="loading-image" src={loading}
                    style={{ width: '100%', height: 'auto', maxWidth: '200px' }} alt="Loading" />
                    :
                    <Form>
                        <Form.Group className="mb-3" controlId="site-name">
                            <Form.Control type="text" name="site-name" onChange={handleInput} value={siteName}
                                placeholder="Site Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="contact-name">
                            <Form.Control type="text" name="contact-name" onChange={handleInput} value={name}
                                placeholder="Contact Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="contact-number">
                            <Form.Control type="text" name="contact-number" onChange={handleInput} value={contactNumber}
                                placeholder="Contact Number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="site-email">
                            <Form.Control type="email" name="site-email" onChange={handleInput} value={email}
                                placeholder="Site Email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="site-address">
                            <Form.Control type="text" name="site-address" onChange={handleInput} value={siteAddress}
                                placeholder="Site Address" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="site-city">
                            <Form.Control type="text" name="site-city" onChange={handleInput} value={siteCity}
                                placeholder="Site City" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="site-country">
                            <Form.Control type="text" name="site-country" onChange={handleInput} value={siteCountry}
                                placeholder="Site Country" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="site-postcode">
                            <Form.Control type="text" name="site-postcode" onChange={handleInput} value={sitePostcode}
                                placeholder="Site Postcode" />
                        </Form.Group>
                    </Form>
                }

            </Modal.Body>
            <Modal.Footer>
                {!updatingSite &&
                    <Button variant="secondary" onClick={() => {
                        setShowEditSiteModal(false)
                        setShowListSitesModal(true)
                    }}>
                        Close
                    </Button>
                }
                <Button style={{ width: '170px', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                    onClick={updateSite}>
                    {
                        updatingSite ?
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            :
                            'Update Site'
                    }

                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditSiteModal;