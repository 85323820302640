import { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import ListContracts from './ListPagesItems/Contract';
import { Context } from '../../../lib/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook, faLocationDot, faPenToSquare, faPhone, faUserTie } from '@fortawesome/free-solid-svg-icons'

const ListSites = props => {
    const { contracts, setShowAddContractModal, setAddContractSiteID, setAddContractSiteName,
        setAddContractSitePostcode, setAddContractSiteAddress, setAddContractSiteContactNumber,
        setAddContractSiteContactName, setShowListSitesModal, setShowEditSiteModal, setEditSiteID,
        setAddSiteCity, setAddSiteCountry } = useContext(Context)
    // Missing from props => clientID, email, siteAddress, sitePostcode
    const { siteName, name, contactNumber, siteIndex, siteAddress, sitePostcode, siteCity, siteCountry, email,
        verificationCode } = props;
    const siteID = props._id;
    return (
        <Accordion.Item eventKey={siteIndex}>
            <Accordion.Header>
                <Col>
                    <Row style={{ width: '100%' }}>
                        <Col xs={12} lg={12} xl={6} style={{ paddingTop: '0px' }}>
                            <Button style={{ float: 'right', marginTop: '7px' }} variant="secondary" onClick={() => {
                                setShowListSitesModal(false)
                                setEditSiteID(siteID);
                                setShowEditSiteModal(true)
                            }} size="sm">
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </Button>
                            <big><strong>{siteName}</strong></big> {verificationCode?.length > 0 && `Code: (${verificationCode})`}<br />
                            <a href={"mailto:" + email}>{email}</a>
                        </Col>
                        <Col xs={12} lg={6} xl={3}>
                            <FontAwesomeIcon icon={faUserTie} /> {name}<br />
                            <FontAwesomeIcon icon={faPhone} /> <a href={"tel:" + contactNumber}>{contactNumber}</a>
                        </Col>
                        <Col xs={12} lg={6} xl={3}>
                            <FontAwesomeIcon icon={faAddressBook} /> {siteAddress}<br />
                            <FontAwesomeIcon icon={faLocationDot} /> {sitePostcode}
                        </Col>
                    </Row>
                </Col>
            </Accordion.Header>
            <Accordion.Body>
                <>
                    {contracts?.length > 0 && contracts.map((contract, index) => (
                        contract.siteID === siteID &&
                        <ListContracts key={contract._id + index} {...contract} index={index} />
                    ))}
                </>
                <div className="d-grid gap-2">
                    <Button onClick={() => {
                        setAddContractSiteID(siteID);
                        setAddContractSiteName(siteName);
                        setAddContractSitePostcode(sitePostcode);
                        setAddContractSiteAddress(siteAddress);
                        setAddContractSiteContactNumber(contactNumber);
                        setAddContractSiteContactName(name);
                        setAddSiteCity(siteCity);
                        setAddSiteCountry(siteCountry);
                        setShowAddContractModal(true);
                        setShowListSitesModal(false);
                    }} className="add-site-button" style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}>
                        Add Contract to {siteName}
                    </Button>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default ListSites;