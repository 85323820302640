import { useContext, useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import loading from '../../../lib/loading-icon.gif';
import { Context } from "../../../lib/Context";

const AddSubContractorModal = props => {
    const { API_LINK, showAddSubContractorModal, getUserProfile, setShowAddSubContractorModal,
        clients, sites, subContractors } = useContext(Context);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [address, setAddress] = useState('')
    const [postcode, setPostcode] = useState('')
    const [vehicleReg, setVehicleReg] = useState('')
    const [DOB, setDOB] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [addingSubContractor, setAddingSubContractor] = useState(false);

    const handleInput = (event) => {
        setErrorMessage('')
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'contact-number':
                setNumber(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'postcode':
                setPostcode(value);
                break;
            case 'vehicle-reg':
                setVehicleReg(value);
                break;
            case 'DOB':
                setDOB(value);
                break;
            default:
                break;
        }
    }

    const addSubContractor = async () => {
        setAddingSubContractor(true);
        let newUserValid = true;
        for (let i = 0; i < clients.length; i++) {
            if (clients[i].email === email) {
                newUserValid = false;
                setErrorMessage('This email is registered to a client.')
                setAddingSubContractor(false);
            }
        }
        if (newUserValid) {
            for (let i = 0; i < sites.length; i++) {
                if (sites[i].email === email) {
                    newUserValid = false;
                    setErrorMessage('This email is already registered to a site.');
                    setAddingSubContractor(false);
                }
            }
            for (let i = 0; i < subContractors.length; i++) {
                if (subContractors[i].email === email) {
                    newUserValid = false;
                    setErrorMessage('This email is already registered to a sub-contractor.');
                    setAddingSubContractor(false);
                }
            }
        }
        if (newUserValid) {
            const response = await fetch(API_LINK + '/api/add-sub-contractor', {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({ name, email, number, address, postcode, vehicleReg, DOB }),
                headers: { 'Content-Type': 'application/json' }
            })
            if (response.status === 200) {
                setShowAddSubContractorModal(false);
                setName('');
                setEmail('');
                setNumber('');
                setVehicleReg('');
                setDOB('');
                await getUserProfile('add-sub-contractor');
                setAddingSubContractor(false);
            } else {
                alert('Registration failed');
            }
        }
    }

    return (
        <>
            <Modal show={showAddSubContractorModal} onHide={() => {
                setShowAddSubContractorModal(false)
                setErrorMessage('');
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Sub Contractor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {addingSubContractor ?
                        <img className="loading-image" src={loading} style={{ width: '100%', height: 'auto' }} alt="Loading" />
                        :
                        <Form>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Control type="text" name="name" onChange={handleInput} placeholder="Name" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Control type="email" name="email" onChange={handleInput} placeholder="Email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="contact-number">
                                <Form.Control type="text" name="contact-number" onChange={handleInput} placeholder="Contact Number" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="address">
                                <Form.Control type="text" name="address" onChange={handleInput} placeholder="Address" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="postcode">
                                <Form.Control type="text" name="postcode" onChange={handleInput} placeholder="Postcode" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="vehicle-reg">
                                <Form.Control type="text" name="vehicle-reg" onChange={handleInput} placeholder="Vehicle Reg" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="DOB">
                                <Form.Control type="text" name="DOB" onChange={handleInput} placeholder="D.O.B" />
                            </Form.Group>
                        </Form>
                    }
                    {errorMessage &&
                        <Alert style={{ textAlign: 'center', marginTop: '20px' }} variant={'danger'}>
                            {errorMessage}
                        </Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {addingSubContractor ?
                        <Button style={{ minWidth: '220px', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                            onClick={addSubContractor} disabled>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        </Button>
                        :
                        <Button style={{ minWidth: '220px', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                            onClick={addSubContractor}>
                            Add New Sub Contractor
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddSubContractorModal;