import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../../lib/Context';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import loading from '../../../lib/loading-icon.gif'
import { Col, Row, Spinner } from 'react-bootstrap';

const EditClientModal = () => {
    const { API_LINK, showEditClientModal, setShowEditClientModal, clients, editClientID, getUserProfile } = useContext(Context)
    const [name, setName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [email, setEmail] = useState('');
    const [siteName, setSiteName] = useState('');
    const [siteAddress, setSiteAddress] = useState('');
    const [sitePostcode, setSitePostcode] = useState('');
    const [siteCity, setSiteCity] = useState('')
    const [siteCountry, setSiteCountry] = useState('')
    const [updatingClient, setUpdatingClient] = useState(false);

    const findClientDetails = useCallback(() => {
        for (let i = 0; i < clients?.length; i++) {
            if (editClientID === clients[i]._id) {
                setName(clients[i].name)
                setContactNumber(clients[i].contactNumber)
                setEmail(clients[i].email)
                setSiteName(clients[i].siteName)
                setSiteAddress(clients[i].siteAddress)
                setSitePostcode(clients[i].sitePostcode)
                setSiteCity(clients[i].siteCity)
                setSiteCountry(clients[i].siteCountry)
            }
        }
    }, [clients, editClientID])

    useEffect(() => {
        findClientDetails();
    }, [findClientDetails])

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'site-contact-name':
                setName(value);
                break;
            case 'site-phone':
                setContactNumber(value);
                break;
            case 'site-email':
                setEmail(value);
                break;
            case 'site-name':
                setSiteName(value);
                break;
            case 'site-address':
                setSiteAddress(value);
                break;
            case 'site-postcode':
                setSitePostcode(value);
                break;
            case 'site-city':
                setSiteCity(value);
                break;
            case 'site-country':
                setSiteCountry(value);
                break;
            default:
                break;
        }
    }

    const updateClient = async (event) => {
        setUpdatingClient(true);
        event.preventDefault();
        const response = await fetch(API_LINK + '/api/update-client', {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify({
                clientID: editClientID, name, contactNumber, email, siteName,
                siteAddress, sitePostcode, siteCity, siteCountry
            }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            await getUserProfile('edit-client');
            setShowEditClientModal(false);
            setUpdatingClient(false);
        } else {
            alert('Updating client failed');
        }
    }

    return (
        <Modal show={showEditClientModal} onHide={() => {
            setShowEditClientModal(false)
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Client Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {updatingClient ? <img className="loading-image" src={loading}
                    style={{ width: '100%', height: 'auto', maxWidth: '200px' }} alt="Loading" />
                    :
                    <>
                        <p>This will be the main contact at head office of the company you are{' '}
                            working for or a home address. <strong>Any email addresses used here will{' '}
                                be able to see all sites that are registered to this client.</strong></p>
                        <Form>
                            <p className="strong">Contact Information</p>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="site-contact-name">
                                        <Form.Control type="text" name="site-contact-name" value={name}
                                            onChange={handleInput} placeholder="Contact Name" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="site-phone">
                                        <Form.Control type="text" name="site-phone" value={contactNumber}
                                            onChange={handleInput} placeholder="Phone Number" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="site-email">
                                        <Form.Control type="email" name="site-email" value={email}
                                            onChange={handleInput} placeholder="Company Email" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="site-name">
                                        <Form.Control type="text" name="site-name" value={siteName}
                                            onChange={handleInput} placeholder="Company Name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="site-address">
                                        <Form.Control type="text" name="site-address" value={siteAddress}
                                            onChange={handleInput} placeholder="Company Address" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="site-city">
                                        <Form.Control type="text" name="site-city" value={siteCity}
                                            onChange={handleInput} placeholder="City" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="site-postcode">
                                        <Form.Control type="text" name="site-postcode" value={sitePostcode}
                                            onChange={handleInput} placeholder="Company Postcode" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="site-country">
                                        <Form.Control type="text" name="site-country" value={siteCountry}
                                            onChange={handleInput} placeholder="Country" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setShowEditClientModal(false)
                }}>
                    Close
                </Button>
                <Button style={{ width: '170px', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                    onClick={updateClient}>
                    {
                        updatingClient ?
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            :
                            'Update Client'
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditClientModal;