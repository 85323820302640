import React, { forwardRef, useContext } from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { Context } from '../../../lib/Context';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import { Col, Row } from 'react-bootstrap';

const ListJobsMenu = (props) => {
    const { jobDisplayState, viewDate, setViewDate, setViewSubContractorID, viewSubContractorID } = props
    const { handleDisplayState, userInfo, handleSwitchPage, subContractors } = useContext(Context)
    const type = userInfo?.type;
    // const type = userInfo?.type

    const DateButton = forwardRef(({ value, onClick }, ref) => (
        <p style={{ margin: '0px' }} onClick={onClick} ref={ref}> View: {value}</p>
    ));

    const changeView = (value) => {
        if (value !== 'completed' && value !== 'day-view') {
            const date = new Date();
            setViewDate(date)
        }
        handleDisplayState(value)
    }

    const getSubContractorName = () => {
        for (let i = 0; i < subContractors?.length; i++) {
            if (subContractors[i]._id === viewSubContractorID) {
                return subContractors[i].name;
            }
        }
    }

    return (
        <Navbar expand="lg" className="bg-body-tertiary" style={{ marginBottom: '15px' }}>
            <Container fluid>
                <Navbar.Brand style={{ width: '100%' }}>
                    <Row>
                        <Col xs={12} md={6} lg={3}>
                            <Dropdown>
                                <Dropdown.Toggle style={{
                                    width: '100%', marginRight: '0px', marginBottom: '7px',
                                    backgroundColor: '#6AAE3E', borderColor: '#6AAE3E'
                                }}
                                    id="view-mode">
                                    {jobDisplayState === 'day-view' && 'Day View'}
                                    {jobDisplayState === 'this-week' && 'This Week'}
                                    {jobDisplayState === 'this-month' && 'Next 30 Days'}
                                    {jobDisplayState === 'this-year' && 'Next Year'}
                                    {jobDisplayState === 'all-time' && 'All Time'}
                                    {jobDisplayState === 'completed' && 'Completed'}
                                    {jobDisplayState === 'all-completed' && 'All Completed'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '100%' }}>
                                    <Dropdown.Item onClick={() => { changeView('day-view') }}>Day View</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { changeView('this-week') }}>This Week</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { changeView('this-month') }}>This Month</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { changeView('this-year') }}>Next Year</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { changeView('all-time') }}>All Time</Dropdown.Item>
                                    <hr />
                                    <Dropdown.Item onClick={() => { changeView('completed') }}>Day Completed</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { changeView('all-completed') }}>All Completed</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                        {(jobDisplayState === 'day-view' || jobDisplayState === 'completed') &&
                            <Col xs={12} md={6} lg={3}>
                                <Button style={{ width: '100%', marginBottom: '7px', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}>
                                    <DatePicker
                                        selected={viewDate}
                                        onChange={(date) => setViewDate(date)}
                                        customInput={<DateButton />}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </Button>
                            </Col>
                        }
                        {type === 'admin' &&
                            <Col xs={12} md={6} lg={3}>
                                <Dropdown>
                                    <Dropdown.Toggle style={{
                                        width: '100%', marginRight: '0px', marginBottom: '7px', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E'
                                    }}
                                        id="view-mode">
                                        {
                                            viewSubContractorID === 'All Sub Contractors' ?
                                                'All Sub Contractors'
                                                : getSubContractorName()
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: '100%' }}>
                                        <Dropdown.Item onClick={() => { setViewSubContractorID('All Sub Contractors') }}>
                                            All Sub Contractors
                                        </Dropdown.Item>
                                        {subContractors?.map((subContractor, index) => (
                                            <Dropdown.Item key={subContractor._id + index} onClick={() => {
                                                setViewSubContractorID(subContractor._id)
                                            }}>
                                                {subContractor.name}
                                            </Dropdown.Item>
                                        ))}

                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        }
                        <Col xs={12} md={6} lg={3}>
                            {type === 'admin' ?
                                (jobDisplayState === 'completed' ?
                                    <Form className="d-flex">
                                        <Form.Control
                                            type="search"
                                            placeholder="Search Invoice #"
                                            className="me-2"
                                            aria-label="Search"
                                        />
                                    </Form>
                                    :
                                    <Form className="d-flex">
                                        <Form.Control
                                            type="search"
                                            placeholder="Search Client"
                                            className="me-2"
                                            aria-label="Search"
                                        />
                                    </Form>
                                )
                                : type !== 'sub-contractor' &&
                                <Button style={{ width: '100%', borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                                    onClick={() => { handleSwitchPage('invoices') }}>
                                    View Invoices
                                </Button>
                            }
                        </Col>
                    </Row>
                </Navbar.Brand>
            </Container>
        </Navbar>

    )
}
export default ListJobsMenu