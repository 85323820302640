import React, { useContext, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import { Context } from '../../lib/Context';

const ListClientsNav = () => {
    const [searchString, setSearchString] = useState('');
    const { clients, setClientsSearchList } = useContext(Context)
    const findClient = (event) => {
        let clientList = [];
        const input = event.target.value.toLowerCase()
        for (let i = 0; i < clients?.length; i++) {
            if (clients[i].name.toLowerCase().startsWith(input) ||
                clients[i].siteName.toLowerCase().startsWith(input) ||
                clients[i].email.toLowerCase().startsWith(input) ||
                clients[i].siteAddress.toLowerCase().startsWith(input)) {
                clientList.push(clients[i]);
            }
        }
        setClientsSearchList(clientList);
        setSearchString(input);
    }

    return (
        <Navbar className="bg-body-tertiary" style={{ marginTop: '60px' }}>
            <Form style={{ width: '100%', marginLeft: '10px', marginRight: '10px' }}>
                <Form.Group controlId="find-client">
                    <Form.Control type="text" value={searchString} onChange={findClient} placeholder="Search Client" />
                </Form.Group>
            </Form>
        </Navbar>
    )
}

export default ListClientsNav