import { Fragment } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import SideNavItems from './SideNavItems/SideNavItems';
import navLogo from '../../lib/Images/nav-logo-alt-center.png'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const SideNav = (props) => {
    const { toggleDrawer, state, anchor, handleSwitchPage, infoRetrieved, handelLoggedOutDisplayPage } = props;

    return (
        <div>
            <Fragment key={'left'}>
                <SwipeableDrawer
                    anchor={'left'}
                    open={state['left']}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Box
                        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                        role="presentation"
                        onClick={toggleDrawer(anchor, false)}
                        onKeyDown={toggleDrawer(anchor, false)}
                    >
                        <List className="d-sm-none" style={{ backgroundColor: '#212529' }}>
                            <ListItem disablePadding>
                                <img src={navLogo} alt="Website Logo" style={{
                                    maxWidth: '80%', marginTop: '20px',
                                    marginLeft: '10%', marginRight: '10%', marginBottom: '20px'
                                }} />
                            </ListItem>
                        </List>
                        <SideNavItems toggleDrawer={toggleDrawer}
                            handleSwitchPage={handleSwitchPage}
                            infoRetrieved={infoRetrieved}
                            handelLoggedOutDisplayPage={handelLoggedOutDisplayPage}
                        />
                    </Box>
                </SwipeableDrawer>
            </Fragment>
        </div>
    );
}
export default SideNav;