import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../../lib/Context';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';


const EditContractModal = () => {
    const { API_LINK, showEditContractModal, setShowEditContractModal, setShowListSitesModal,
        viewPage, editContractID, contracts, getUserProfile } = useContext(Context);
    // jobName, jobDuration, jobFrequency, jobPrice, jobDescription
    const [jobName, setJobName] = useState('');
    const [jobDuration, setJobDuration] = useState('Contract Duration');
    const [jobFrequency, setJobFrequency] = useState('Job Frequency');
    const [jobPrice, setJobPrice] = useState('');
    const [jobDescription, setJobDescription] = useState('No comments');

    const findContractDetails = useCallback(() => {
        for (let i = 0; i < contracts?.length; i++) {
            if (editContractID === contracts[i]._id) {
                setJobName(contracts[i].jobName);
                setJobDuration(contracts[i].jobDuration);
                setJobFrequency(contracts[i].jobFrequency);
                setJobPrice(contracts[i].jobPrice);
                setJobDescription(contracts[i].jobDescription);
            }
        }
    }, [contracts, editContractID])

    useEffect(() => {
        findContractDetails();
    }, [findContractDetails])

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'job-name':
                setJobName(value);
                break;
            case 'job-duration':
                setJobDuration(value);
                break;
            case 'job-frequency':
                setJobFrequency(value);
                break;
            case 'job-price':
                setJobPrice(value);
                break;
            case 'job-description':
                setJobDescription(value);
                break;
            default:
                break;
        }
    }

    const updateContract = async (event) => {
        event.preventDefault();
        const response = await fetch(API_LINK + '/api/update-contract', {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify({ contractID: editContractID, jobName, jobDuration, jobFrequency, jobPrice, jobDescription }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            if (viewPage === 'list-clients') {
                setShowEditContractModal(false)
                await getUserProfile('edit-contract-from-clients');
            } else {
                setShowEditContractModal(false)
                await getUserProfile('edit-contract');
            }
        } else {
            alert('Updating contract failed');
        }
    }

    return (
        <Modal show={showEditContractModal} onHide={() => {
            if (viewPage === 'list-clients') {
                setShowListSitesModal(true)
                setShowEditContractModal(false)
            } else {
                setShowEditContractModal(false)
            }
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Contract Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* // siteID, siteName, jobName, jobDuration, jobFrequency, jobDate, jobPrice */}
                    <Form.Group className="mb-3" controlId="job-name">
                        <Form.Control type="text" name="job-name" onChange={handleInput} value={jobName}
                            placeholder="Job Name i.e. 'Windows'" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="job-price">
                        <Form.Control type="text" name="job-price" onChange={handleInput} value={jobPrice}
                            placeholder="Job Price" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="job-description">
                        <Form.Control
                            as="textarea"
                            name="job-description"
                            onChange={handleInput}
                            value={jobDescription}
                            placeholder="Comments on the job..."
                            style={{ height: '100px' }}
                        />
                    </Form.Group>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            Duration:
                            <Dropdown style={{ marginRight: '20px' }}>
                                <Dropdown.Toggle variant="outline-secondary" id="select-duration">
                                    {jobDuration}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { setJobDuration('One Off') }}>One Off</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setJobDuration('3 Months') }}>3 Months</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setJobDuration('6 Months') }}>6 Months</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setJobDuration('12 Months') }}>12 Months</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setJobDuration('2 Years') }}>2 Years</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setJobDuration('3 Years') }}>3 Years</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setJobDuration('4 Years') }}>4 Years</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setJobDuration('5 Years') }}>5 Years</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col>
                            {jobDuration !== 'One Off' &&
                                <>
                                    Frequency:
                                    <Dropdown style={{ display: 'block', width: '100%' }}>
                                        <Dropdown.Toggle variant="outline-secondary" id="select-frequency">
                                            {jobFrequency}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ width: '100%' }}>
                                            <Dropdown.Item onClick={() => { setJobFrequency('Weekly') }}>Weekly</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobFrequency('Fortnightly') }}>Fortnightly</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobFrequency('Monthly') }}>Monthly</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobFrequency('8 Weekly') }}>8 Weekly</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobFrequency('Biannually') }}>Biannually</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobFrequency('Triannually') }}>Triannually</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobFrequency('Quarterly') }}>Quarterly</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setJobFrequency('Annually') }}>Annually</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            }
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    if (viewPage === 'list-clients') {
                        setShowListSitesModal(true)
                        setShowEditContractModal(false)
                    } else {
                        setShowEditContractModal(false)
                    }
                }}>
                    Close
                </Button>
                <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} onClick={updateContract}>
                    Update Contract
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditContractModal;