import { useContext } from 'react';
import { Context } from '../../lib/Context';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import ListClients from './ListPages/ListClients';
import ListSubContractors from './ListPages/ListSubContractors';
import loading from '../../lib/loading-icon.gif'
import '../../App.css'
import ListContracts from './ListPages/ListContracts';
import HomePage from './HomePage';
import ListJobs from './ListPages/ListJobs';
import ServicesPage from './ServicesPage';
import HowItWorks from './HowItWorks';
import ListInvoices from './ListPages/ListInvoices';
import ListInvoiceNav from '../NavBars/ListInvoiceNav';
import ListClientsNav from '../NavBars/ListClientsNav';

const PageContainer = (props) => {
    const { logo, displayPage } = props;
    const { userInfo, viewPage, clients, infoRetrieved, clientsSearchList } = useContext(Context);
    const type = userInfo?.type;
    return (
        <>
            <Container className="page-container">
                {!infoRetrieved && <img className="loading-image" src={loading}
                    style={{ marginTop: '100px', width: '100%', height: 'auto', maxWidth: '200px' }} alt="Loading" />}
                {infoRetrieved && !userInfo?.email && displayPage === 'home-page' && <HomePage logo={logo} />}
                {infoRetrieved && !userInfo?.email && displayPage === 'about-us' && <ServicesPage logo={logo} />}
                {infoRetrieved && !userInfo?.email && displayPage === 'how-it-works' && <HowItWorks logo={logo} />}
                {type === 'admin' && viewPage === 'list-clients' && <ListClientsNav />}
                <Row>
                    <Col>
                        {infoRetrieved && userInfo?.email &&
                            viewPage === 'list-clients' ?
                            <Accordion >
                                <Row>
                                    {clients?.length > 0 && clients.map((client, index) => (
                                        clientsSearchList?.length > 0 ? clientsSearchList.map((listClient, i) => (
                                            (listClient._id === client._id &&
                                                <Col xs={12} md={6} xl={4} key={'clients-list-' + client._id + index}>
                                                    <ListClients key={listClient._id} {...listClient} clientIndex={i} />
                                                </Col>)
                                        ))
                                            :
                                            <Col xs={12} md={6} xl={4} key={'clients-' + client._id + index}>
                                                <ListClients key={client._id} {...client} clientIndex={index} />
                                            </Col>
                                    ))}
                                </Row>
                            </Accordion>
                            :
                            infoRetrieved && viewPage === 'list-sub-contractors' ?
                                <ListSubContractors />
                                :
                                viewPage === 'pending-contracts' ?
                                    <ListContracts type={'pending'} />
                                    :
                                    viewPage === 'live-contracts' ?
                                        <ListContracts type={'live'} />
                                        :
                                        viewPage === 'jobs' ?
                                            <ListJobs />
                                            :
                                            viewPage === 'invoices' &&
                                            <>
                                                {type === 'admin' && <ListInvoiceNav />}
                                                <ListInvoices />
                                            </>
                        }
                    </Col>
                </Row>
            </Container>
            <div style={{ textAlign: 'center', marginTop: '25px', marginBottom: '30px' }}>
                © Copyright | Exterior Cleaning Services LTD<br />
                Web App created by <a style={{ color: 'black' }} href="https://digitalredneck.co.uk" target="blank">Digital Redneck</a>
            </div>
        </>
    )
}

export default PageContainer;