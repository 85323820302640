import { useCallback, useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Context } from '../../lib/Context';
import loading from '../../lib/loading-icon.gif';

const CompleteJobModal = () => {
    const { API_LINK, showCompleteJobModal, completeJobModalID, jobs, setJobs, setShowCompleteJobModal, setInvoices } = useContext(Context);
    const [siteName, setSiteName] = useState('');
    const [completingJob, setCompletingJob] = useState(false);
    const [jobComments, setJobComments] = useState('');
    const [imagesReceived, setImagesReceived] = useState(false);
    const [files, setFiles] = useState([]);

    const findJobDetails = useCallback(() => {
        for (let i = 0; i < jobs?.length; i++) {
            if (completeJobModalID === jobs[i]._id) {
                setSiteName(jobs[i].siteName);
            }
        }
    }, [jobs, completeJobModalID])

    useEffect(() => {
        findJobDetails();
    }, [findJobDetails])

    const completeJob = async (images) => {
        const response = await fetch(API_LINK + '/api/complete-job', {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify({
                jobID: completeJobModalID, images, jobComments
            }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            fetch(API_LINK + '/api/profile', {
                credentials: 'include',
                method: 'GET'
            }).then(response => {
                response.json().then(data => {
                    setJobs(data.jobs);
                    setInvoices(data.invoices);
                    setCompletingJob(false);
                    setShowCompleteJobModal(false);
                    setFiles([]);
                    setImagesReceived(false);
                })
            })
        } else {
            alert('Registration failed');
        }
    }

    const uploadImages = async () => {
        setCompletingJob(true);
        if (files.length === 0) {
            alert('Please select an image!')
            setCompletingJob(false);
            setImagesReceived(false);
            return;
        }
        if (files.length > 2) {
            alert('Select up to 2 images please!')
            setCompletingJob(false);
            setImagesReceived(false);
            return;
        }
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('images', files[i]);
        }
        const response = await fetch(API_LINK + '/image/upload', {
            method: 'POST',
            body: formData
        });
        const data = await response.json();
        let images = [];
        const savedFiles = data.files;
        for (let i = 0; i < savedFiles.length; i++) {
            images.push(savedFiles[i].key);
        }
        await completeJob(images);
    }

    const onImageChange = (event) => {
        const submittingFiles = event.target.files;
        if (submittingFiles.length > 0) {
            setImagesReceived(true);
        } else {
            setImagesReceived(false);
        }
        setFiles(submittingFiles);

    }

    const buildComment = (event) => {
        let currentComment = event.target.value;
        setJobComments(currentComment);
    }

    return (
        <Modal show={showCompleteJobModal} onHide={() => { setShowCompleteJobModal(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>{siteName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {completingJob ?
                    <img className="loading-image" style={{ width: '100%', height: 'auto' }} src={loading} alt="Loading" />
                    :
                    <>
                        <p><strong>Please select up to two images for this job.</strong></p>
                        <form style={{ marginBottom: '30px' }}>
                            <input onChange={onImageChange} type='file' accept='image/*' alt='Image upload' multiple />
                        </form>
                        {imagesReceived &&
                            <>
                                <p><strong>Please add any relevant comments.</strong></p>
                                <Form><Form.Group className="mb-3" controlId="job-comment">
                                    <Form.Control
                                        as="textarea"
                                        name="job-comment"
                                        onChange={buildComment}
                                        placeholder="Your message"
                                        style={{ height: '200px' }}
                                    />
                                </Form.Group>
                                </Form>
                            </>}
                    </>
                }
            </Modal.Body>
            {!completingJob &&
                <Modal.Footer>
                    {imagesReceived ?
                        <Button style={{ minWidth: '100%', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                            onClick={uploadImages}>
                            COMPLETE JOB
                        </Button>
                        :
                        <Button style={{ minWidth: '100%', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                            onClick={uploadImages} disabled>
                            COMPLETE JOB
                        </Button>}
                </Modal.Footer>
            }
        </Modal>
    )
}

export default CompleteJobModal;