import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../../lib/Context';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const EditSubContractorModal = () => {
    const { API_LINK, showEditSubContractorModal, setShowEditSubContractorModal, subContractors,
        editSubContractorID, getUserProfile } = useContext(Context);
    // name, email, number, address, postcode, vehicleReg, DOB
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [address, setAddress] = useState('')
    const [postcode, setPostcode] = useState('')
    const [vehicleReg, setVehicleReg] = useState('')
    const [DOB, setDOB] = useState('')

    const findSubContractorDetails = useCallback(() => {
        for (let i = 0; i < subContractors?.length; i++) {
            if (editSubContractorID === subContractors[i]._id) {
                setName(subContractors[i].name);
                setEmail(subContractors[i].email);
                setNumber(subContractors[i].number);
                setAddress(subContractors[i].address);
                setPostcode(subContractors[i].postcode);
                setVehicleReg(subContractors[i].vehicleReg);
                setDOB(subContractors[i].DOB);
            }
        }
    }, [subContractors, editSubContractorID])

    useEffect(() => {
        findSubContractorDetails();
    }, [findSubContractorDetails])

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'contact-number':
                setNumber(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'postcode':
                setPostcode(value);
                break;
            case 'vehicle-reg':
                setVehicleReg(value);
                break;
            case 'DOB':
                setDOB(value);
                break;
            default:
                break;
        }
    }

    const updateSubContractor = async (event) => {
        event.preventDefault();
        const response = await fetch(API_LINK + '/api/update-sub-contractor', {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify({ subContractorID: editSubContractorID, name, email, number, address, postcode, vehicleReg, DOB }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            setShowEditSubContractorModal(false);
            await getUserProfile('edit-sub-contractor');
        } else {
            alert('Updating sub contractor failed');
        }
    }

    return (
        <Modal show={showEditSubContractorModal} onHide={() => { setShowEditSubContractorModal(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Sub-Contractor Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Control type="text" name="name" onChange={handleInput} value={name}
                            placeholder="Name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control type="email" name="email" onChange={handleInput} value={email}
                            placeholder="Email" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contact-number">
                        <Form.Control type="text" name="contact-number" onChange={handleInput} value={number}
                            placeholder="Contact Number" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="address">
                        <Form.Control type="text" name="address" onChange={handleInput} value={address}
                            placeholder="Address" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="postcode">
                        <Form.Control type="text" name="postcode" onChange={handleInput} value={postcode}
                            placeholder="Postcode" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="vehicle-reg">
                        <Form.Control type="text" name="vehicle-reg" onChange={handleInput} value={vehicleReg}
                            placeholder="Vehicle Reg" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="DOB">
                        <Form.Control type="text" name="DOB" onChange={handleInput} value={DOB}
                            placeholder="D.O.B" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setShowEditSubContractorModal(false) }}>
                    Close
                </Button>
                <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} onClick={updateSubContractor}>
                    Update Sub-Contractor
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditSubContractorModal;