import logo from '../../lib/Images/ecshome.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConservatoryCleaning from '../../lib/Images/ConservatoryCleaning.jpg'
import GutterCleaning from '../../lib/Images/GutterCleaning.jpg'
import PressureWashing from '../../lib/Images/PressureWashing.jpg'
import RoofCleaning from '../../lib/Images/RoofCleaning.jpg'
import Tree_Cut from '../../lib/Images/Tree_Cut.jpg'
import Boat_Clean from '../../lib/Images/Boat_Clean.jpg'
import trimming from '../../lib/Images/trimming.jpg'
import grasscut from '../../lib/Images/grasscut.jpg'
import Cladding_Wash from '../../lib/Images/Cladding_Wash.jpg'
import tonyCleaningHouse from '../../lib/Images/tonyCleaninghouse_slideshow.jpg'
import facia from '../../lib/Images/facia.jpg'
import SolarRoof from '../../lib/Images/SolarRoof.jpg'
import '../../css/ServicesPage.css'
import { useContext } from 'react';
import { Context } from '../../lib/Context';

const ServicesPage = () => {
    const { setShowContactModal } = useContext(Context)
    return (
        <div style={{ marginTop: '60px' }}>
            <img src={logo} alt="Website Logo" style={{ maxWidth: '100%' }} />
            <Row>
                <Col style={{ textAlign: 'center', marginTop: '30px', marginBottom: '30px' }}>
                    <h1>What we can offer you</h1>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={ConservatoryCleaning} alt="Conservatory Cleaning" />
                    <div className='service'>
                        Conservatory Cleaning
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={SolarRoof} alt="Solar Roof" />
                    <div className='service'>
                        Solar Panel Cleaning
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={GutterCleaning} alt="Gutter Cleaning" />
                    <div className='service'>
                        Gutter Cleaning
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={PressureWashing} alt="Pressure Washing" />
                    <div className='service'>
                        Pressure Washing
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={RoofCleaning} alt="Roof Cleaning" />
                    <div className='service'>
                        Roof Cleaning
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={Tree_Cut} alt="Tree Cut" />
                    <div className='service'>
                        Tree Surgery
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={Boat_Clean} alt="Boat Clean" />
                    <div className='service'>
                        Boat Cleaning
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={trimming} alt="Garden Clearance" />
                    <div className='service'>
                        Garden Clearance
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={grasscut} alt="Grass Cutting" />
                    <div className='service'>
                        Grass Cutting
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={Cladding_Wash} alt="Cladding Wash" />
                    <div className='service'>
                        Cladding
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={tonyCleaningHouse} alt="Tony Cleaning House" />
                    <div className='service'>
                        Window Cleaning
                    </div>
                </Col>
                <Col xs={6} md={4} lg={3}>
                    <img style={{ width: '100%', height: 'auto' }} src={facia} alt="Facia and Soffit" />
                    <div className='service'>
                        Facia and Soffit
                    </div>
                </Col>
            </Row>
            <Row style={{ backgroundColor: 'black', marginTop: '50px', marginBottom: '0px' }}>
                <Col style={{ textAlign: 'center', marginTop: '50px', marginBottom: '50px', color: 'white' }}>
                    <h1>Contact Us</h1>
                    <p style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
                        If you would like to get in touch with us then you can email us by{' '}
                        <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { setShowContactModal(true) }}>
                            clicking here
                        </span>
                        , or you can WhatsApp us{' '}
                        images of the job you would like us to do for you using 07383618032 and we'll get back to you ASAP.
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default ServicesPage;