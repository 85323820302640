import { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { Context } from '../../../lib/Context';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

const EditContractorModal = () => {
    const { API_LINK, showEditProfileModal, setShowEditProfileModal, loadingImage, userInfo } = useContext(Context);
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [bankName, setBankName] = useState('');
    const [sortCode, setSortCode] = useState('');
    const [accountNo, setAccountNo] = useState('');
    const [contractorName, setContractorName] = useState('');
    const [contractorNumber, setContractorNumber] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [email, setEmail] = useState('');
    const [taxRate, setTaxRate] = useState(null);
    const [paymentBuffer, setPaymentBuffer] = useState(null);
    const [dataFound, setDataFound] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [editInvoice, setEditInvoice] = useState(false);
    const type = userInfo?.type;

    const handleEditView = () => {
        setPasswordsMatch(true);
        setEditInvoice(!editInvoice)
    }

    const findContractorDetails = useCallback(() => {
        fetch(API_LINK + '/api/contractor-info', {
            credentials: 'include',
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                if (type === 'admin') {
                    const {
                        address, postcode, city, country, companyName, bankName, sortCode, accountNo,
                        taxRate, paymentBuffer, contractorName, contractorNumber } = data?.contractor
                    let contractorEmail = data?.contractor.email
                    setAddress(address)
                    setPostcode(postcode)
                    setCity(city)
                    setCountry(country)
                    setCompanyName(companyName)
                    setBankName(bankName)
                    setSortCode(sortCode)
                    setAccountNo(accountNo)
                    setTaxRate(taxRate)
                    setContractorName(contractorName)
                    setContractorNumber(contractorNumber)
                    setPaymentBuffer(paymentBuffer)
                    setEmail(contractorEmail)
                }
                setDataFound(true);
            })
        })
    }, [API_LINK, type])

    useEffect(() => {
        findContractorDetails();
    }, [findContractorDetails])

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'postcode':
                setPostcode(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'country':
                setCountry(value);
                break;
            case 'company-name':
                setCompanyName(value);
                break;
            case 'bank-name':
                setBankName(value);
                break;
            case 'sort-code':
                setSortCode(value);
                break;
            case 'acc-no':
                setAccountNo(value);
                break;
            case 'tax-rate':
                setTaxRate(value);
                break;
            case 'payment-buffer':
                setPaymentBuffer(value);
                break;
            case 'contractor-name':
                setContractorName(value)
                break;
            case 'contractor-number':
                setContractorNumber(value);
                break;
            case 'password':
                setPasswordsMatch(true);
                setPassword(value);
                break;
            case 'confirm-password':
                setPasswordsMatch(true);
                setPasswordConfirm(value);
                break;
            default:
                break;
        }
    }

    const updateContractor = async (event) => {
        event.preventDefault();
        if (password === passwordConfirm) {
            const response = await fetch(API_LINK + '/api/update-contractor', {
                method: 'PATCH',
                credentials: 'include',
                body: JSON.stringify({
                    address, postcode, city, country, companyName, bankName,
                    sortCode, accountNo, taxRate, paymentBuffer, contractorName,
                    contractorNumber, password
                }),
                headers: { 'Content-Type': 'application/json' }
            })
            if (response.status === 200) {
                setShowEditProfileModal(false);
                setPassword('');
                setPasswordConfirm('');
            } else {
                alert('Updating job failed');
            }
        } else {
            setPassword('');
            setPasswordConfirm('');
            setEditInvoice(false);
            setPasswordsMatch(false);
        }

    }

    return (
        <>
            <Modal show={showEditProfileModal} onHide={() => {
                setPassword('');
                setPasswordConfirm('');
                setEditInvoice(false);
                setPasswordsMatch(true);
                setShowEditProfileModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Profile Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!dataFound ? <img className="loading-image" src={loadingImage} alt="Loading" />
                        :
                        <>
                            <Form>
                                {!editInvoice ?
                                    <>
                                        <p><strong>Authentication Info:</strong></p>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Control type="email" name="email" onChange={handleInput}
                                                        value={email}
                                                        placeholder="Email" disabled />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                New Password:
                                                <Form.Group className="mb-3" controlId="password">
                                                    <Form.Control type="password" name="password" onChange={handleInput}
                                                        value={password}
                                                        autoComplete='off'
                                                        placeholder="New Password" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                Confirm New Password:
                                                <Form.Group className="mb-3" controlId="confirm-password">
                                                    <Form.Control type="password" name="confirm-password" onChange={handleInput}
                                                        value={passwordConfirm}
                                                        autoComplete='off'
                                                        placeholder="Confirm Password" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <p><strong>Account Info:</strong></p>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="contractor-name">
                                                    <Form.Control type="text" name="contractor-name" onChange={handleInput} value={contractorName}
                                                        placeholder="Name" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="contractor-number">
                                                    <Form.Control type="text" name="contractor-number" onChange={handleInput} value={contractorNumber}
                                                        placeholder="Phone" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="address">
                                                    <Form.Control type="text" name="address" onChange={handleInput} value={address}
                                                        placeholder="Address" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="postcode">
                                                    <Form.Control type="text" name="postcode" onChange={handleInput} value={postcode}
                                                        placeholder="Post Code" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="city">
                                                    <Form.Control type="text" name="city" onChange={handleInput} value={city}
                                                        placeholder="City" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="country">
                                                    <Form.Control type="text" name="country" onChange={handleInput} value={country}
                                                        placeholder="Country" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <p><strong>Payment information:</strong></p>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="bank-name">
                                                    <Form.Control type="text" name="bank-name" onChange={handleInput} value={bankName}
                                                        placeholder="Bank Name" />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={5}>
                                                <Form.Group className="mb-3" controlId="sort-code">
                                                    <Form.Control type="text" name="sort-code" onChange={handleInput} value={sortCode}
                                                        placeholder="Sort Code" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="company-name">
                                                    <Form.Control type="text" name="company-name" onChange={handleInput} value={companyName}
                                                        placeholder="Company Name" />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={5}>
                                                <Form.Group className="mb-3" controlId="acc-no">
                                                    <Form.Control type="text" name="acc-no" onChange={handleInput} value={accountNo}
                                                        placeholder="Account Number" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <p><strong>Invoice Configuration:</strong></p>
                                        <Row>
                                            <Col>
                                                Tax Rate (for VAT):
                                                <Form.Group className="mb-3" controlId="tax-rate">
                                                    <Form.Control type="text" name="tax-rate" onChange={handleInput} value={taxRate}
                                                        placeholder="Tax Rate" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                Payment Buffer:
                                                <Form.Group className="mb-3" controlId="payment-buffer">
                                                    <Form.Control type="text" name="payment-buffer" onChange={handleInput} value={paymentBuffer}
                                                        placeholder="Payment Buffer" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                {!passwordsMatch &&
                                    <Alert variant="danger">
                                        Those passwords didn't match, please try again!
                                    </Alert>
                                }
                            </Form>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {!editInvoice ?
                        <Button style={{ backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }} onClick={handleEditView}>
                            <FontAwesomeIcon icon={faCreditCard} /> Edit Invoice
                        </Button>
                        :
                        <Button style={{ backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }} onClick={handleEditView}>
                            <FontAwesomeIcon icon={faUserAlt} /> Edit Profile
                        </Button>
                    }
                    <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} onClick={updateContractor}>
                        UPDATE SETTINGS
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditContractorModal;