import { useContext, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Context } from '../../lib/Context';
import validateEmail from '../../lib/Functions/validateEmail'

const ContactUsModal = () => {
    const { API_LINK, showContactModal, setShowContactModal, userInfo } = useContext(Context);
    const [userEmail, setUserEmail] = useState('')
    const [userName, setUserName] = useState('')
    const [message, setMessage] = useState('')
    const [emailValid, setEmailValid] = useState('');
    const [nameValid, setNameValid] = useState('');
    const [messageValid, setMessageValid] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
    const email = userInfo?.email;

    const sendMessage = () => {
        fetch(API_LINK + '/api/contact-us', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userEmail: email ? email : userEmail,
                userName, message
            }),
            credentials: 'include'
        }).then(response => {
            response.json().then(data => {
                setMessage('')
                setUserEmail('')
                setUserName('')
                setEmailValid(false);
                setNameValid(false);
                setMessageValid(false);
                setShowThankYou(true);
            })

        })
    };

    const handleInput = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        switch (name) {
            case 'user-email':
                setUserEmail(value.toLowerCase());
                let emailValid = validateEmail(value.toLowerCase());
                setEmailValid(emailValid);
                break;
            case 'user-name':
                setUserName(value);
                setNameValid(value.length > 2);
                break;
            default:
                setMessage(value);
                setMessageValid(value.length > 20);
                break;
        }
    }

    return (
        <>
            <Modal show={showContactModal} onHide={() => {
                setShowThankYou(false)
                setShowContactModal(false)
            }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Get in touch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!showThankYou ?
                        <>
                            <p>If you would like to get in touch, you can give us a ring on <a href="tel:+447830133970">07830133970</a>{' '}
                                or alternatively you can fill in the form below to send us an email. We'll get back to you as quickly{' '}
                                as possible.</p>
                            <Form>
                                {/* // siteID, siteName, jobName, jobDuration, jobFrequency, jobDate, jobPrice */}
                                <Form.Group className="mb-3" controlId="user-name">
                                    <Form.Control type="text" name="user-name" value={userName}
                                        onChange={handleInput} placeholder="Your Name" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="user-email">
                                    {email ?
                                        <Form.Control type="email" name="user-email"
                                            value={email} onChange={handleInput} placeholder="Your email" disabled />
                                        :
                                        <Form.Control type="email" name="user-email" value={userEmail}
                                            onChange={handleInput} placeholder="Your email" />
                                    }

                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email-body">
                                    <Form.Control
                                        as="textarea"
                                        name="message"
                                        onChange={handleInput}
                                        value={message}
                                        placeholder="Your message"
                                        style={{ height: '200px' }}
                                    />
                                </Form.Group>
                            </Form>
                        </>
                        :
                        <Alert style={{ textAlign: 'center' }} variant={'success'}>
                            Thanks for sending your message. We will get back to you as soon as possible.
                        </Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowContactModal(false);
                        setShowThankYou(false);
                    }}>
                        {!showThankYou ? 'Cancel' : 'Close'}
                    </Button>
                    {(emailValid || email) && nameValid && messageValid &&
                        <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} onClick={sendMessage}>
                            Send Email
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ContactUsModal;