import './App.css';
import { useContext, useEffect, useState } from 'react';
import { Context } from './lib/Context';
import PageContainer from './Elements/Pages/PageContainer';
import logo from './lib/Images/ecshome.jpg';
import NavMain from './Elements/NavBars/NavMain';
import SideNavColapse from './Elements/NavBars/SideNav';

function App() {
  const { resetProfileInfo, API_LINK, setShowLoginModal, infoRetrieved,
    setInfoRetrieved, getUserProfile, emailIsValid, setEmailIsValid } = useContext(Context);
  const [displayPage, setDisplayPage] = useState('home-page');
  const [showSideMenu, setShowSideMenu] = useState(false);

  const toggleSideMenu = () => {
    setShowSideMenu(!showSideMenu)
  }

  useEffect(() => {
    setInfoRetrieved(false);
    getUserProfile('login')
  }, [setInfoRetrieved, getUserProfile])

  const logout = () => {
    setInfoRetrieved(false);
    fetch(API_LINK + '/api/logout', {
      method: 'POST',
      credentials: 'include'
    }).then(() => {
      resetProfileInfo();
      setShowLoginModal(false);
      setInfoRetrieved(true);
    })
  };

  return (
    <>
      {showSideMenu &&
        <SideNavColapse />
      }
      <NavMain
        logout={logout}
        setShowLoginModal={setShowLoginModal}
        emailIsValid={emailIsValid}
        setEmailIsValid={setEmailIsValid}
        infoRetrieved={infoRetrieved}
        getUserProfile={getUserProfile}
        displayPage={displayPage}
        setDisplayPage={setDisplayPage}
        toggleSideMenu={toggleSideMenu}
      />
      <PageContainer
        infoRetrieved={infoRetrieved}
        logo={logo}
        displayPage={displayPage}
      />
    </>
  );
}

export default App;
