import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockRotateLeft, faComment, faMoneyBill, faTruckFast } from '@fortawesome/free-solid-svg-icons';

const ListServices = (props) => {
    const { jobName, jobFrequency, jobPrice, jobDescription, contractID, currentContractID } = props;
    return (
        contractID === currentContractID &&
        <Row>
            <Col xs={12} md={3} lg={3}>
                <FontAwesomeIcon icon={faTruckFast} /> {jobName}
            </Col>
            <Col xs={12} md={3} lg={3}>
                <FontAwesomeIcon icon={faClockRotateLeft} /> {jobFrequency}
            </Col>
            <Col xs={12} md={3} lg={4}>
                <FontAwesomeIcon icon={faComment} /> {jobDescription}
            </Col>
            <Col xs={12} md={3} lg={2} style={{ textAlign: 'right' }}>
                {jobPrice !== 0 &&
                    <><FontAwesomeIcon icon={faMoneyBill} /> £{jobPrice.toFixed(2)}</>
                }
            </Col>
        </Row>



    )
}

export default ListServices;