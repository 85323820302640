import React, { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import easyinvoice from 'easyinvoice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import '../../css/InvoiceModal.css'
import { Context } from '../../lib/Context';
import '../../css/InvoiceModal.css'

function MonthlyInvoiceModal() {
    const { showMonthlyInvoiceModal, setShowMonthlyInvoiceModal, invoiceMonthlyModalID, setInvoiceMonthlyModalID, invoices,
        loadingImage } = useContext(Context);

    const [companyName, setCompanyName] = useState('')
    const [address, setAddress] = useState('')
    const [postcode, setPostcode] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [siteName, setSiteName] = useState('')
    const [siteAddress, setSiteAddress] = useState('')
    const [sitePostcode, setSitePostcode] = useState('')
    const [siteCity, setSiteCity] = useState('')
    const [siteCountry, setSiteCountry] = useState('')
    const [invoiceDate, setInvoiceDate] = useState('')
    const [dueDate, setDueDate] = useState('')
    const [jobName, setJobName] = useState('')
    const [taxRate, setTaxRate] = useState('')
    const [jobPrice, setJobPrice] = useState('')
    const [bankName, setBankName] = useState('')
    const [sortCode, setSortCode] = useState('')
    const [accountNo, setAccountNo] = useState('')
    const [invoiceNumber, setInvoiceNo] = useState('')
    const [isPaid, setIsPaid] = useState('');
    const [invoiceLoaded, setInvoiceLoaded] = useState(false);

    const printInvoice = useCallback(async () => {
        var data = {
            "images": {
                "logo": "https://lewin-manager.s3.eu-west-2.amazonaws.com/1689757650964ecs-logo.jpg",
                "background": isPaid ?
                    'https://lewin-manager.s3.eu-west-2.amazonaws.com/1692355684627paid-invoice.jpg'
                    :
                    'https://lewin-manager.s3.eu-west-2.amazonaws.com/1692355684630unpaid-invoice.jpg'
            },
            "sender": { "company": companyName, "address": address, "zip": postcode, "city": city, "country": country },
            "client": { "company": siteName, "address": siteAddress, "zip": sitePostcode, "city": siteCity, "country": siteCountry },
            "information": { "number": invoiceNumber, "date": invoiceDate, "due-date": dueDate },
            "products": [
                { "quantity": 1, "description": jobName, "tax-rate": taxRate, "price": jobPrice }
            ],
            "bottom-notice": `<strong>Payment Details:</strong><br />${companyName}<br />Bank Name: ${bankName}<br />Sort Code: ${sortCode}<br />Acc No: ${accountNo}`,
            "settings": { "currency": "GBP" },
            "translate": { "products": "Service", "quantity": "Qty", "vat": "VAT" }
        };
        const result = await easyinvoice.createInvoice(data);
        easyinvoice.render('monthly-invoice', result.pdf).then(() => {
            setInvoiceLoaded(true);
        });

    }, [companyName, address, postcode, city, country, siteName, siteAddress, sitePostcode, siteCity, siteCountry,
        invoiceDate, dueDate, jobName, taxRate, jobPrice, bankName, sortCode, accountNo, invoiceNumber, isPaid])

    const findInvoice = useCallback(async () => {
        console.log(invoiceMonthlyModalID)
        for (let i = 0; i < invoices.length; i++) {
            if (invoiceMonthlyModalID === invoices[i]._id) {
                setCompanyName(invoices[i].companyName);
                setAddress(invoices[i].address);
                setPostcode(invoices[i].postcode);
                setCity(invoices[i].city);
                setCountry(invoices[i].country);
                setSiteName(invoices[i].siteName);
                setSiteAddress(invoices[i].siteAddress);
                setSitePostcode(invoices[i].sitePostcode);
                setSiteCity(invoices[i].siteCity);
                setSiteCountry(invoices[i].siteCountry);
                setInvoiceDate(invoices[i].invoiceDate);
                setDueDate(invoices[i].dueDate);
                setJobName(invoices[i].jobName);
                setTaxRate(invoices[i].taxRate);
                setJobPrice(invoices[i].jobPrice);
                setBankName(invoices[i].bankName);
                setSortCode(invoices[i].sortCode);
                setAccountNo(invoices[i].accountNo);
                setInvoiceNo(invoices[i].invoiceNumber);
                setIsPaid(invoices[i].isPaid);
            }
        }
    }, [invoices, invoiceMonthlyModalID])

    useEffect(() => {
        setInvoiceLoaded(false);
        if (invoiceMonthlyModalID !== '') {
            findInvoice().then(() => {
                printInvoice();
            });
        }
    }, [findInvoice, printInvoice, invoiceMonthlyModalID])

    const closeModal = () => {
        setShowMonthlyInvoiceModal(false);
        setInvoiceLoaded(false);
        setInvoiceMonthlyModalID('');
    }


    return (
        <Modal size="lg" show={showMonthlyInvoiceModal} onHide={() => {
            closeModal();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{siteName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {!invoiceLoaded && invoiceMonthlyModalID !== '' &&
                        <img className="loading-image" style={{ width: '100%', height: 'auto', maxWidth: '200px' }}
                            src={loadingImage} alt="Loading" />}
                    <Row>
                        <div id="monthly-invoice"></div>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ minWidth: '170px', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                    onClick={printInvoice}>
                    <><FontAwesomeIcon icon={faFileArrowDown} />{' '} Download Invoice</>
                </Button>
                <Button variant="secondary" onClick={() => {
                    closeModal();
                }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MonthlyInvoiceModal