import { useContext, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import loading from '../../../lib/loading-icon.gif'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../../lib/Context';
import { Col, Row } from 'react-bootstrap';


const AddSiteModal = () => {
    const { API_LINK, showAddSiteModal, addSiteClientID, setShowListSitesModal, getUserProfile,
        addingSite, setAddingSite, setShowAddSiteModal, clients, subContractors, sites } = useContext(Context);
    const [email, setEmail] = useState('');
    const [siteName, setSiteName] = useState('');
    const [siteAddress, setSiteAddress] = useState('');
    const [sitePostcode, setSitePostcode] = useState('');
    const [name, setName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [siteCity, setSiteCity] = useState('')
    const [siteCountry, setSiteCountry] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const clientID = addSiteClientID;

    const handleInput = (event) => {
        setErrorMessage('');
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'site-name':
                setSiteName(value);
                break;
            case 'site-email':
                setEmail(value);
                break;
            case 'site-address':
                setSiteAddress(value);
                break;
            case 'site-postcode':
                setSitePostcode(value);
                break;
            case 'site-city':
                setSiteCity(value);
                break;
            case 'site-country':
                setSiteCountry(value);
                break;
            case 'contact-name':
                setName(value);
                break;
            case 'contact-number':
                setContactNumber(value);
                break;
            default:
                break;
        }
    }

    const addNewSite = async () => {
        setAddingSite(true);
        let newUserValid = true;
        for (let i = 0; i < clients.length; i++) {
            if (clients[i].email === email && clientID !== clients[i]._id) {
                newUserValid = false;
                setErrorMessage('This email is registered to another client.')
                setAddingSite(false);
            }
        }
        if (newUserValid) {
            for (let i = 0; i < sites.length; i++) {
                if (sites[i].email === email && clientID !== sites[i].clientID) {
                    newUserValid = false;
                    setErrorMessage('This email is registered to a site in another client.')
                    setAddingSite(false);
                }
            }
            for (let i = 0; i < subContractors.length; i++) {
                if (subContractors[i].email === email) {
                    newUserValid = false;
                    setErrorMessage('This email is registered to a sub-contractor')
                    setAddingSite(false);
                }
            }
        }
        if (newUserValid) {
            const response = await fetch(API_LINK + '/api/register-site', {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({ clientID, email, siteName, siteAddress, siteCity, siteCountry, sitePostcode, name, contactNumber }),
                headers: { 'Content-Type': 'application/json' }
            })
            if (response.status === 200) {
                setEmail('')
                setSiteName('')
                setSiteAddress('')
                setSitePostcode('')
                setName('')
                setContactNumber('')
                getUserProfile('add-site');
                setAddingSite(false);
            } else {
                alert('Registration failed');
            }
        }
    }

    return (
        <Modal show={showAddSiteModal} onHide={() => {
            setShowAddSiteModal(false);
            setShowListSitesModal(true);
            setErrorMessage('');
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Site</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {addingSite ?
                    <img className="loading-image" src={loading} style={{ width: '100%', height: 'auto' }} alt="Loading" />
                    :
                    <>
                        <p>This is where you add a new site. This will be where the job will take place. {' '}
                            <strong>If this is a different email address to the clients email then that person will{' '}
                                only be able to see details for this site.</strong></p>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-name">
                                        <Form.Control type="text" name="site-name" onChange={handleInput} placeholder="Site Name" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-address">
                                        <Form.Control type="text" name="site-address" onChange={handleInput} placeholder="Site Address" />
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="contact-name">
                                        <Form.Control type="text" name="contact-name" onChange={handleInput} placeholder="Contact Name" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-city">
                                        <Form.Control type="text" name="site-city" onChange={handleInput} value={siteCity}
                                            placeholder="Site City" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="contact-number">
                                        <Form.Control type="text" name="contact-number" onChange={handleInput} placeholder="Contact Number" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-country">
                                        <Form.Control type="text" name="site-country" onChange={handleInput} value={siteCountry}
                                            placeholder="Site Country" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-email">
                                        <Form.Control type="email" name="site-email" onChange={handleInput} placeholder="Site Email" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="site-postcode">
                                        <Form.Control type="text" name="site-postcode" onChange={handleInput} placeholder="Site Postcode" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <Alert style={{ textAlign: 'center' }} variant={'danger'}>
                            A welcome email will be sent to this email address.
                        </Alert>
                    </>

                }
                {errorMessage &&
                    <Alert style={{ textAlign: 'center', marginTop: '20px' }} variant={'danger'}>
                        {errorMessage}
                    </Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                {addingSite ?
                    <Button onClick={addNewSite} style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} disabled>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </Button>
                    :
                    <Button onClick={addNewSite} style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}>
                        Add New Site
                    </Button>
                }

            </Modal.Footer>
        </Modal>
    )
}

export default AddSiteModal;