
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../../css/Job.css'
import Dropdown from 'react-bootstrap/Dropdown';
import {
    faAddressBook,
    faClockRotateLeft,
    faComment,
    faFileLines,
    faLocationDot, faMagnifyingGlass, faMoneyBill, faPenToSquare, faPhone, faStoreAlt, faTruckFast, faUserTie
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Context } from '../../../../lib/Context';
import SubContractorItem from '../../../DropDownItems/SubContractorItem';

const JobAdmin = (props) => {
    const { siteName, jobName, dateDue, subContractorID, siteAddress, sitePostcode, siteContactName,
        siteContactNumber, completed, dateAttended, jobPrice, serviceID, jobCost, jobTime, jobDescription } = props;
    const { API_LINK, subContractors, setJobs, setCompleteJobModalID, setInvoiceJobModalID, setShowJobDetailsModal, setShowPerJobInvoiceModal,
        setShowCompleteJobModal, userInfo, setShowEditJobModal, setEditJobModalID, setDetailsJobModalID, services, contracts } = useContext(Context);
    const [subContractorName, setSubContractorName] = useState('Assign Sub Contractor')
    const dueDate = new Date(dateDue);
    const attendedDate = new Date(dateAttended);
    const [startDate, setStartDate] = useState(dueDate);
    const [monthlyFee, setMonthlyFee] = useState(0);
    let attendedDay = attendedDate.getDate();
    let attendedMonth = attendedDate.getMonth() + 1;
    let attendedYear = attendedDate.getFullYear();
    const jobID = props._id;
    const type = userInfo?.type;

    const getSubContractorsName = useCallback(() => {
        for (let i = 0; i < subContractors?.length; i++) {
            if (subContractorID === subContractors[i]._id) {
                setSubContractorName(subContractors[i].name);
            }
        }
    }, [setSubContractorName, subContractorID, subContractors])

    const getContractInfo = useCallback(() => {
        for (let i = 0; i < services.length; i++) {
            if (services[i]._id === serviceID) {
                const contractID = services[i].contractID;
                for (let j = 0; j < contracts.length; j++) {
                    if (contracts[j]._id === contractID) {
                        setMonthlyFee(contracts[j].monthlyFee.toFixed(2));
                    }
                }
            }
        }
    }, [services, contracts, serviceID])

    useEffect(() => {
        getSubContractorsName();
        getContractInfo();
    }, [getSubContractorsName, getContractInfo])

    const addSubContractorToJob = async (event) => {
        let subContractorID = event.target.name;
        console.log('subContractorID')
        console.log(subContractorID)
        const response = await fetch(API_LINK + '/api/change-subcontractor', {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify({
                jobID, subContractorID
            }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            fetch(API_LINK + '/api/profile', {
                credentials: 'include',
                method: 'GET'
            }).then(response => {
                response.json().then(data => {
                    setJobs(data.jobs);
                })
            })
        } else {
            alert('Registration failed');
        }
    }

    const changeDate = async (date) => {
        setStartDate(date)
        // Send off request to change date of job.
        const response = await fetch(API_LINK + '/api/update-job-date', {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify({
                jobID, dueDate: date
            }),
            headers: { 'Content-Type': 'application/json' }
        })
        if (response.status === 200) {
            fetch(API_LINK + '/api/profile', {
                credentials: 'include',
                method: 'GET'
            }).then(response => {
                response.json().then(data => {
                    setJobs(data.jobs);
                })
            })
        } else {
            alert('Registration failed');
        }
    }

    const DateButton = forwardRef(({ value, onClick }, ref) => (
        <p style={{ margin: '0px' }} onClick={onClick} ref={ref}>{value}</p>
    ));

    return (
        <>
            <Card body style={{ marginBottom: '20px' }}>
                <Container fluid>
                    <Row>

                        <Col xs={12} md={3} >
                            <FontAwesomeIcon icon={faStoreAlt} /> {siteName}<br />
                            <FontAwesomeIcon icon={faTruckFast} /> {jobName}<br />
                            <FontAwesomeIcon icon={faClockRotateLeft} /> {jobTime}
                        </Col>
                        <Col xs={12} md={3} >
                            <FontAwesomeIcon icon={faUserTie} /> {siteContactName}<br />
                            <FontAwesomeIcon icon={faPhone} /> <a href={"tel:" + siteContactNumber}>{siteContactNumber}</a>
                        </Col>
                        <Col xs={12} md={3} >
                            <FontAwesomeIcon icon={faAddressBook} /> {siteAddress}<br />
                            <FontAwesomeIcon icon={faLocationDot} /> {sitePostcode}
                        </Col>
                        <Col xs={12} md={3} >
                            {jobPrice === 0 ?
                                <><strong style={{ float: 'right' }}>
                                    <FontAwesomeIcon icon={faMoneyBill} />{' '}
                                    Monthly Contract (£{monthlyFee})
                                </strong><br />
                                    <span style={{ color: '#E6893B', fontWeight: 'bold', float: 'right' }}>
                                        <FontAwesomeIcon icon={faMoneyBill} /> £{jobCost.toFixed(2)}
                                    </span>
                                </>
                                :
                                <div style={{ float: 'right', fontWeight: 'bold' }}>
                                    <FontAwesomeIcon icon={faMoneyBill} />{' '}
                                    <strong>£{jobPrice.toFixed(2)}</strong><br />
                                    <span style={{ color: '#E6893B' }}>
                                        <FontAwesomeIcon icon={faMoneyBill} /> £{jobCost.toFixed(2)}
                                    </span>
                                </div>
                            }

                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} lg={3} md={6}>
                            <Button variant="secondary" onClick={() => {
                                setEditJobModalID(jobID);
                                setShowEditJobModal(true);
                            }} style={{ float: 'right', marginRight: '0px', marginTop: '7px', marginBottom: '7px', width: '100%' }}>
                                EDIT CONTACT <FontAwesomeIcon icon={faPenToSquare} />
                            </Button>

                        </Col>
                        <Col xs={12} lg={3} md={6} >
                            {!completed ?
                                // 'Due Date: ' + attendingDay + '/' + attendingMonth + '/' + attendingYear
                                <Button variant="secondary" style={{ width: '100%', marginTop: '7px', marginBottom: '7px' }}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => changeDate(date)}
                                        customInput={<DateButton />}
                                        dateFormat="dd/MM/yyyy"
                                        calendarClassName="date-picker"
                                    />
                                </Button>
                                : 'Date Attended: ' + attendedDay + '/' + attendedMonth + '/' + attendedYear} <br />
                            {completed && type === 'admin' && 'Attended by: ' + subContractorName}
                        </Col>
                        <Col xs={12} lg={3} md={6} >
                            <Dropdown style={{
                                width: '100%', marginTop: '7px', marginRight: '5px', marginBottom: '10px'
                            }}>
                                <Dropdown.Toggle style={{
                                    width: '100%',
                                    borderColor: '#3D91F6', backgroundColor: '#3D91F6'
                                }} id="dropdown-basic">
                                    {subContractorName}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '100%' }}>
                                    {subContractors?.length > 0 && subContractors.map((subContractor, index) => (
                                        <SubContractorItem key={index} {...subContractor} addSubContractorToJob={addSubContractorToJob} />
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col xs={12} lg={3} md={6} >
                            {!completed &&
                                <Button style={{
                                    float: 'right', width: '100%', marginTop: '7px', marginBottom: '10px',
                                    backgroundColor: '#6AAE3E', borderColor: '#6AAE3E'
                                }} onClick={() => {
                                    setShowCompleteJobModal(true);
                                    setCompleteJobModalID(jobID);
                                }}>
                                    COMPLETE JOB
                                </Button>
                            }
                            {completed &&
                                <Col md={3}>
                                    <Button style={{ float: 'right', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                                        onClick={() => {
                                            setDetailsJobModalID(jobID);
                                            setShowJobDetailsModal(true);
                                        }}>
                                        DETAILS <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </Button>
                                    {type !== 'sub-contractor' &&
                                        <Button style={{
                                            float: 'right', marginRight: '10px',
                                            backgroundColor: '#E6893B', borderColor: '#E6893B'
                                        }} onClick={() => {
                                            setInvoiceJobModalID(jobID);
                                            setShowPerJobInvoiceModal(true);
                                        }}>
                                            INVOICE <FontAwesomeIcon icon={faFileLines} />
                                        </Button>
                                    }
                                </Col>
                            }
                        </Col>
                    </Row>
                    {jobDescription !== 'No comments' &&
                        <>
                            <hr />
                            <FontAwesomeIcon icon={faComment} /> {jobDescription}
                        </>
                    }
                </Container>
            </Card>

        </>
    );
}

export default JobAdmin;