import { useContext, useState } from "react";
import { Context } from "../../../lib/Context";
import JobView from "./ListPagesItems/JobView";
import ListJobsMenu from "./ListJobsMenu";
import Container from 'react-bootstrap/Container';

const ListJobs = () => {
    const { jobs, jobDisplayState, userInfo } = useContext(Context)
    const date = new Date()
    const [viewDate, setViewDate] = useState(date)
    const [viewSubContractorID, setViewSubContractorID] = useState('All Sub Contractors');

    return (
        userInfo?.email &&
        <Container style={{ marginBottom: '30px', marginTop: '60px' }}>
            <ListJobsMenu
                jobDisplayState={jobDisplayState} setViewDate={setViewDate}
                viewDate={viewDate} setViewSubContractorID={setViewSubContractorID}
                viewSubContractorID={viewSubContractorID}
            />
            {jobs?.length > 0 && jobs.map((job, index) => (
                <JobView {...job} key={job._id} displayState={jobDisplayState}
                    index={index} viewDate={viewDate} viewSubContractorID={viewSubContractorID} />
            ))}
        </Container>

    )

}
export default ListJobs;