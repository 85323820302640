import { useCallback, useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const JobDetailsModal = () => {
    const { showJobDetailsModal, jobs, detailsJobModalID, setShowJobDetailsModal, setDetailsJobModalID } = useContext(Context)
    const [siteName, setSiteName] = useState('');
    const [dateAttended, setDateAttended] = useState('');
    const [images, setImages] = useState([]);
    const [jobComments, setJobComments] = useState();

    const findJobDetails = useCallback(() => {
        for (let i = 0; i < jobs?.length; i++) {
            if (detailsJobModalID === jobs[i]._id) {
                const date = new Date(jobs[i].dateAttended);
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                setSiteName(jobs[i].siteName);
                setDateAttended(day + '/' + month + '/' + year);
                setImages(jobs[i].images);
                setJobComments(jobs[i].jobComments);
            }
        }
    }, [jobs, detailsJobModalID])

    useEffect(() => {
        findJobDetails();
    }, [findJobDetails])

    return (
        <Modal size="lg" show={showJobDetailsModal} onHide={() => {
            setShowJobDetailsModal(false)
            setDetailsJobModalID('');
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{siteName} - {dateAttended}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <p style={{ marginBottom: '20px' }}>{jobComments}</p>
                        {images.map((image, index) => {
                            return (
                                <Col key={index}>
                                    <img src={process.env.REACT_APP_AWS_BASE_URL + '/' + image} alt={image}
                                        style={{ maxWidth: '100%' }} />
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setShowJobDetailsModal(false);
                    setDetailsJobModalID('');
                }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default JobDetailsModal;