import { useCallback, useContext, useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import easyinvoice from 'easyinvoice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import '../../css/InvoiceModal.css'

const InvoiceModal = () => {
    const { API_LINK, showPerJobInvoiceModal, jobs, invoiceJobModalID, setShowPerJobInvoiceModal, setInvoiceJobModalID, loadingImage } = useContext(Context)
    const [siteName, setSiteName] = useState('');
    const [dateAttended, setDateAttended] = useState('');
    const [dataFound, setDataFound] = useState(true);

    const printInvoice = useCallback(async () => {
        setDataFound(false);
        if (invoiceJobModalID !== '') {
            fetch(API_LINK + '/api/invoice-data/' + invoiceJobModalID, {
                credentials: 'include',
                method: 'GET'
            }).then(res => {
                res.json().then(async response => {
                    const { companyName, address, postcode, city, country, siteName, siteAddress, sitePostcode, siteCity, siteCountry,
                        invoiceDate, dueDate, jobName, taxRate, jobPrice, bankName, sortCode, accountNo, invoiceNumber, isPaid } = response;
                    var data = {
                        "images": {
                            "logo": "https://lewin-manager.s3.eu-west-2.amazonaws.com/1689757650964ecs-logo.jpg",
                            "background": isPaid ?
                                'https://lewin-manager.s3.eu-west-2.amazonaws.com/1692355684627paid-invoice.jpg'
                                :
                                'https://lewin-manager.s3.eu-west-2.amazonaws.com/1692355684630unpaid-invoice.jpg'
                        },
                        "sender": { "company": companyName, "address": address, "zip": postcode, "city": city, "country": country },
                        "client": { "company": siteName, "address": siteAddress, "zip": sitePostcode, "city": siteCity, "country": siteCountry },
                        "information": { "number": invoiceNumber, "date": invoiceDate, "due-date": dueDate },
                        "products": [
                            { "quantity": 1, "description": jobName, "tax-rate": taxRate, "price": jobPrice }
                        ],
                        "bottom-notice": `<strong>Payment Details:</strong><br />${companyName}<br />Bank Name: ${bankName}<br />Sort Code: ${sortCode}<br />Acc No: ${accountNo}`,
                        "settings": { "currency": "GBP" },
                        "translate": { "products": "Service", "quantity": "Qty", "vat": "VAT" }
                    };
                    const result = await easyinvoice.createInvoice(data);
                    easyinvoice.render('pdf', result.pdf).then(() => {
                        setDataFound(true);
                    });

                })
            })
        }

    }, [API_LINK, invoiceJobModalID])

    const findJobDetails = useCallback(() => {
        for (let i = 0; i < jobs?.length; i++) {
            if (invoiceJobModalID === jobs[i]._id) {
                const date = new Date(jobs[i].dateAttended);
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                setSiteName(jobs[i].siteName);
                setDateAttended(day + '/' + month + '/' + year);
            }
        }
        printInvoice();
    }, [jobs, invoiceJobModalID, printInvoice])

    useEffect(() => {
        findJobDetails();
    }, [findJobDetails])



    return (
        <Modal size="lg" show={showPerJobInvoiceModal} onHide={() => {
            if (dataFound) {
                setShowPerJobInvoiceModal(false);
                setInvoiceJobModalID('');
            }
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{siteName} - {dateAttended}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        {!dataFound &&
                            <>
                                <img style={{ maxWidth: '30%', marginLeft: 'auto', marginRight: 'auto' }}
                                    className="loading-image" src={loadingImage} alt="Loading" />
                            </>
                        }
                        <div id="pdf"></div>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ minWidth: '170px', backgroundColor: '#6AAE3E', borderColor: '#6AAE3E' }}
                    onClick={printInvoice}>
                    {!dataFound ?
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        :
                        <><FontAwesomeIcon icon={faFileArrowDown} />{' '} Download Invoice</>
                    }
                </Button>
                <Button variant="secondary" onClick={() => {
                    if (dataFound) {
                        setShowPerJobInvoiceModal(false)
                        setInvoiceJobModalID('')
                    }
                }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default InvoiceModal;