import React, { useContext } from 'react'
import { Context } from '../../../../lib/Context';
import Invoice from './Invoice';

const AdminInvoices = (props) => {
    const { invoices } = useContext(Context);
    const { siteID } = props;
    return (
        <>
            {invoices?.map((invoice, index) => (
                invoice.siteID === siteID && !invoice.jobID &&
                <Invoice key={'list-monthly-invoices' + invoice._id + index} invoice={invoice} />
            ))}
            {invoices?.map((invoice, index) => (
                invoice.siteID === siteID && !invoice.contractID &&
                <Invoice key={'list-pay-per-job-invoices' + invoice._id + index} invoice={invoice} />
            ))}
        </>
    )
}

export default AdminInvoices