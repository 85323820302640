import React from 'react'
import Form from 'react-bootstrap/Form';
import validateEmail from '../lib/Functions/validateEmail';

const ForgotPasswordForm = (props) => {
    const { setResetPassword, checkUserType, email, setEmail, setShowLoginError } = props;

    const buildForgotEmail = async (event) => {
        setShowLoginError(false);
        const currentText = event.target.value.toLowerCase();
        let emailValid = validateEmail(currentText);
        setEmail(currentText);
        if (emailValid) {
            await checkUserType(currentText);
        }
        // If email is valid then check type.
        // Depending on type, send request off to backend
        // Delete the password from the account and then go back to login form with email populated.
    }

    return (
        <>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Please enter in your email address.</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onInput={buildForgotEmail} />
                </Form.Group>
            </Form>
            <div><p style={{
                textAlign: 'center',
                cursor: 'pointer',
                color: '#0d6efd'
            }} onClick={() => { setResetPassword(false) }}>Back to Login</p></div>
        </>
    )
}

export default ForgotPasswordForm