
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../../css/Job.css'
import {
    faAddressBook, faClockRotateLeft, faComment, faLocationDot, faMoneyBill, faPhone, faStoreAlt, faTruckFast, faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Context } from '../../../../lib/Context';

const JobSubContractor = (props) => {
    const { siteName, jobName, dateDue, siteAddress, sitePostcode, siteContactName,
        siteContactNumber, jobCost, jobTime, jobDescription } = props;
    const { setCompleteJobModalID, setShowCompleteJobModal } = useContext(Context);
    const jobID = props._id;
    const date = new Date(dateDue)
    const day = date.getUTCDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return (
        <>
            <Card body style={{ marginBottom: '20px' }}>
                <Container fluid>
                    <Row>

                        <Col xs={12} md={6} lg={3} >
                            <FontAwesomeIcon icon={faStoreAlt} /> {siteName}<br />
                            <FontAwesomeIcon icon={faTruckFast} /> {jobName}<br />
                            <FontAwesomeIcon icon={faClockRotateLeft} /> {jobTime}
                        </Col>
                        <Col xs={12} md={6} lg={3} >
                            <FontAwesomeIcon icon={faAddressBook} /> {siteAddress}<br />
                            <FontAwesomeIcon icon={faLocationDot} /> {sitePostcode}
                        </Col>
                        <Col xs={12} md={6} lg={2} >
                            <FontAwesomeIcon icon={faUserTie} /> {siteContactName}<br />
                            <FontAwesomeIcon icon={faPhone} /> <a href={"tel:" + siteContactNumber}>{siteContactNumber}</a>
                        </Col>
                        <Col xs={12} md={6} lg={4} >
                            <Row>
                                <Col xs={12} lg={4}>
                                    <p><strong>{day}/{month}/{year}<br />
                                        <FontAwesomeIcon icon={faMoneyBill} /> £{jobCost.toFixed(2)}</strong></p>
                                </Col>
                                <Col xs={12} lg={8}>
                                    <Button style={{ float: 'right', width: '100%', marginTop: '7px', marginBottom: '10px' }}
                                        onClick={() => {
                                            setShowCompleteJobModal(true);
                                            setCompleteJobModalID(jobID);
                                        }}>
                                        COMPLETE JOB
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {jobDescription !== 'No comments' &&
                        <>
                            <hr />
                            <FontAwesomeIcon icon={faComment} /> {jobDescription}
                        </>
                    }
                </Container>
            </Card>

        </>
    );
}

export default JobSubContractor;