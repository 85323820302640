
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../../css/Job.css'
import {
    faAddressBook,
    faFileLines,
    faLocationDot, faMagnifyingGlass, faPhone, faStoreAlt, faTruckFast, faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Context } from '../../../../lib/Context';

const JobContract = (props) => {
    const { siteName, jobName, subContractorID, siteAddress, sitePostcode, siteContactName,
        siteContactNumber, dateAttended, jobPrice, dateDue } = props;
    const { subContractors, setInvoiceModalID, setShowJobDetailsModal, setShowInvoiceModal,
        userInfo, setDetailsJobModalID, invoices } = useContext(Context);
    const [subContractorName, setSubContractorName] = useState('Assign Sub Contractor')
    const attendedDate = new Date(dateAttended);
    let attendedDay = attendedDate.getDate();
    let attendedMonth = attendedDate.getMonth() + 1;
    let attendedYear = attendedDate.getFullYear();
    const dueDate = new Date(dateDue);
    let dueDay = dueDate.getDate();
    let dueMonth = dueDate.getMonth() + 1;
    let dueYear = dueDate.getFullYear();
    const jobID = props._id;
    const type = userInfo?.type;

    const getSubContractorsName = useCallback(() => {
        for (let i = 0; i < subContractors?.length; i++) {
            if (subContractorID === subContractors[i]._id) {
                setSubContractorName(subContractors[i].name);
            }
        }
    }, [setSubContractorName, subContractorID, subContractors]);

    useEffect(() => {
        getSubContractorsName();
    }, [getSubContractorsName]);

    const findInvoiceID = () => {
        for (let i = 0; i < invoices.length; i++) {
            if (jobID === invoices[i].jobID) {
                setInvoiceModalID(invoices[i]._id)
                setShowInvoiceModal(true);
            }
        }
    }

    return (
        <>
            <Card body style={{ marginBottom: '20px' }}>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={6} lg={3} >
                            <FontAwesomeIcon icon={faStoreAlt} /> {siteName}<br />
                            <FontAwesomeIcon icon={faTruckFast} /> {jobName}
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <FontAwesomeIcon icon={faUserTie} /> {siteContactName}<br />
                            <FontAwesomeIcon icon={faPhone} /> <a href={"tel:" + siteContactNumber}>{siteContactNumber}</a>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <FontAwesomeIcon icon={faAddressBook} /> {siteAddress}<br />
                            <FontAwesomeIcon icon={faLocationDot} /> {sitePostcode}
                        </Col>
                        <Col xs={12} md={6} lg={3} style={{ paddingTop: '10px' }}>
                            <Row>
                                <Col>
                                    <Button style={{
                                        float: 'right', width: '100%', marginBottom: '10px',
                                        backgroundColor: '#6AAE3E', borderColor: '#6AAE3E'
                                    }} onClick={() => {
                                        setDetailsJobModalID(jobID);
                                        setShowJobDetailsModal(true);
                                    }}>
                                        DETAILS <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </Button>
                                </Col>
                                {type !== 'sub-contractor' && jobPrice !== 0 &&
                                    <Col>
                                        <Button style={{
                                            float: 'right', marginRight: '0px', width: '100%', marginBottom: '10px',
                                            backgroundColor: '#E6893B', borderColor: '#E6893B'
                                        }} onClick={findInvoiceID}>
                                            <FontAwesomeIcon icon={faFileLines} />{' '}
                                            <strong>£{jobPrice}</strong>
                                        </Button>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                    <p style={{ width: '100%', textAlign: 'right', marginBottom: '0px' }}>
                        Due: {dueDay}/{dueMonth}/{dueYear} | {subContractorName} ({attendedDay}/{attendedMonth}/{attendedYear}){' '}
                    </p>
                </Container>
            </Card>
        </>
    );
}

export default JobContract;