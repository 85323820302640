import { createContext, useCallback, useState } from "react";
import loadingImage from '../lib/loading-icon.gif'

export const Context = createContext({});

const ContextProvider = ({ children }) => {
    const API_LINK = process.env.REACT_APP_API_LINK;

    const [viewPage, setViewPage] = useState('list-clients'); // Site navigation variable.
    const [infoRetrieved, setInfoRetrieved] = useState(true); // Responsible for showing loading image.
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [userType, setUserType] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);

    const [addingContract, setAddingContract] = useState(false);
    const [addingSite, setAddingSite] = useState(false);

    const [userInfo, setUserInfo] = useState({});
    const [clients, setClients] = useState({});
    const [clientsSearchList, setClientsSearchList] = useState([]);
    const [sites, setSites] = useState({});
    const [contracts, setContracts] = useState({});
    const [subContractors, setSubContractors] = useState({});
    const [jobs, setJobs] = useState({});
    const [services, setServices] = useState({});
    const [invoices, setInvoices] = useState({});

    const [jobDisplayState, setJobDisplayState] = useState('day-view');

    const [showValidateEmailModal, setShowValidateEmailModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showAddClientModal, setShowAddClientModal] = useState(false);
    const [showAddSiteModal, setShowAddSiteModal] = useState(false);
    const [showAddContractModal, setShowAddContractModal] = useState(false);
    const [showAddSubContractorModal, setShowAddSubContractorModal] = useState(false);
    const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
    const [showPerJobInvoiceModal, setShowPerJobInvoiceModal] = useState(false);
    const [showMonthlyInvoiceModal, setShowMonthlyInvoiceModal] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showCompleteJobModal, setShowCompleteJobModal] = useState(false);
    const [showApproveJobModal, setShowApproveJobModal] = useState(false);
    const [showListSitesModal, setShowListSitesModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);

    const [showEditClientModal, setShowEditClientModal] = useState(false);
    const [showEditContractModal, setShowEditContractModal] = useState(false);
    const [showEditJobModal, setShowEditJobModal] = useState(false);
    const [showEditSiteModal, setShowEditSiteModal] = useState(false);
    const [showEditSubContractorModal, setShowEditSubContractorModal] = useState(false);

    const [addSiteClientID, setAddSiteClientID] = useState('');
    const [addContractSiteID, setAddContractSiteID] = useState('');
    const [addContractSiteName, setAddContractSiteName] = useState('');
    const [addContractSiteContactName, setAddContractSiteContactName] = useState('');
    const [addContractSiteContactNumber, setAddContractSiteContactNumber] = useState('');
    const [addContractSiteAddress, setAddContractSiteAddress] = useState('');
    const [addContractSitePostcode, setAddContractSitePostcode] = useState('');
    const [addSiteCity, setAddSiteCity] = useState('');
    const [addSiteCountry, setAddSiteCountry] = useState('');
    const [listSitesClientID, setListSitesClientID] = useState('');
    const [listSitesSiteID, setListSitesSiteID] = useState('');
    const [detailsJobModalID, setDetailsJobModalID] = useState('');
    const [invoiceJobModalID, setInvoiceJobModalID] = useState(''); // Job ID
    const [invoiceMonthlyModalID, setInvoiceMonthlyModalID] = useState(''); // Invoice ID
    const [invoiceModalID, setInvoiceModalID] = useState(''); // Invoice ID
    const [completeJobModalID, setCompleteJobModalID] = useState('')
    const [editJobModalID, setEditJobModalID] = useState('');
    const [approveContractID, setApproveContractID] = useState('');
    const [selectClientID, setSelectClientID] = useState('');

    const [editClientID, setEditClientID] = useState('');
    const [editContractID, setEditContractID] = useState('');
    const [editJobID, setEditJobID] = useState('');
    const [editSiteID, setEditSiteID] = useState('');
    const [editSubContractorID, setEditSubContractorID] = useState('');

    const resetProfileInfo = () => {
        setViewPage(null);
        setUserInfo(null);
        setSites(null);
        setClients(null);
        setJobs(null);
        setContracts(null);
    }

    const handleSwitchPage = (page) => {
        setViewPage(page)
        setJobDisplayState('this-week')
    }

    const handleDisplayState = (name) => {
        handleSwitchPage('jobs')
        setJobDisplayState(name)
    }

    const getUserProfile = useCallback(type => {
        setInfoRetrieved(false);
        setEmailIsValid(false);
        fetch(API_LINK + '/api/profile', {
            credentials: 'include',
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                setSites(data.sites);
                setUserInfo(data.info);
                setClients(data.clients);
                setContracts(data.contracts);
                setSubContractors(data.subContractors);
                setJobs(data.jobs);
                setServices(data.services);
                setInvoices(data.invoices);
                setEmailVerified(data.emailVerified);
                setInfoRetrieved(true);
                switch (type) {
                    case 'add-contract':
                        setAddingContract(false);
                        setShowAddContractModal(false);
                        setShowListSitesModal(true);
                        break;
                    case 'add-site':
                        setShowAddSiteModal(false);
                        setAddingSite(false);
                        setShowListSitesModal(true);
                        break;
                    case 'add-sub-contractor':
                        setShowAddSubContractorModal(false);
                        break;
                    case 'edit-site':
                        setViewPage('list-clients');
                        setShowListSitesModal(true);
                        break;
                    case 'edit-job':
                        setViewPage('jobs');
                        break;
                    case 'edit-contract':
                        setViewPage('pending-contracts');
                        break;
                    case 'edit-contract-from-clients':
                        setViewPage('list-clients');
                        setShowListSitesModal(true);
                        break;
                    case 'edit-sub-contractor':
                        setViewPage('list-sub-contractors');
                        break;
                    case 'edit-client':
                        setViewPage('list-clients');
                        break;
                    case 'login':
                        setViewPage('jobs');
                        break;
                    case 'update-job-date':
                        setViewPage('jobs');
                        break;
                    default:
                        break;
                }
                if (data.info !== undefined) {
                    setShowValidateEmailModal(!data.emailVerified);
                }
            })
        })
    }, [API_LINK])


    return (<Context.Provider value={{
        API_LINK, loadingImage,

        viewPage, setViewPage,
        infoRetrieved, setInfoRetrieved,
        emailIsValid, setEmailIsValid,
        userType, setUserType,
        emailVerified, setEmailVerified,

        addingContract, setAddingContract,
        addingSite, setAddingSite,

        userInfo, setUserInfo,
        clients, setClients,
        clientsSearchList, setClientsSearchList,
        sites, setSites,
        contracts, setContracts,
        subContractors, setSubContractors,
        jobs, setJobs,
        services, setServices,
        invoices, setInvoices,

        jobDisplayState, setJobDisplayState,
        handleDisplayState, handleSwitchPage,

        showValidateEmailModal, setShowValidateEmailModal,
        showLoginModal, setShowLoginModal,
        showAddClientModal, setShowAddClientModal,
        showAddSiteModal, setShowAddSiteModal,
        showAddContractModal, setShowAddContractModal,
        showAddSubContractorModal, setShowAddSubContractorModal,
        showJobDetailsModal, setShowJobDetailsModal,
        showPerJobInvoiceModal, setShowPerJobInvoiceModal,
        showMonthlyInvoiceModal, setShowMonthlyInvoiceModal,
        showCompleteJobModal, setShowCompleteJobModal,
        showApproveJobModal, setShowApproveJobModal,
        showListSitesModal, setShowListSitesModal,
        showContactModal, setShowContactModal,
        showEditClientModal, setShowEditClientModal,
        showEditContractModal, setShowEditContractModal,
        showEditJobModal, setShowEditJobModal,
        showEditSiteModal, setShowEditSiteModal,
        showEditSubContractorModal, setShowEditSubContractorModal,
        showEditProfileModal, setShowEditProfileModal,
        showInvoiceModal, setShowInvoiceModal,

        invoiceModalID, setInvoiceModalID,
        addSiteClientID, setAddSiteClientID,
        addContractSiteID, setAddContractSiteID,
        addContractSiteName, setAddContractSiteName,
        addContractSiteContactName, setAddContractSiteContactName,
        addContractSiteContactNumber, setAddContractSiteContactNumber,
        addContractSiteAddress, setAddContractSiteAddress,
        addContractSitePostcode, setAddContractSitePostcode,
        addSiteCity, setAddSiteCity,
        addSiteCountry, setAddSiteCountry,
        listSitesClientID, setListSitesClientID,
        listSitesSiteID, setListSitesSiteID,
        detailsJobModalID, setDetailsJobModalID,
        completeJobModalID, setCompleteJobModalID,
        invoiceJobModalID, setInvoiceJobModalID,
        invoiceMonthlyModalID, setInvoiceMonthlyModalID,
        editJobModalID, setEditJobModalID,
        approveContractID, setApproveContractID,
        selectClientID, setSelectClientID,

        editClientID, setEditClientID,
        editContractID, setEditContractID,
        editJobID, setEditJobID,
        editSiteID, setEditSiteID,
        editSubContractorID, setEditSubContractorID,

        getUserProfile, resetProfileInfo
    }}>
        {children}
    </Context.Provider>
    )
}

export default ContextProvider;