
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../../css/Job.css'
import {
    faAddressBook, faLocationDot, faMagnifyingGlass, faMoneyBill, faPhone, faStoreAlt, faTruckFast, faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Context } from '../../../../lib/Context';

const JobSubContractorContract = (props) => {
    const { siteName, jobName, siteAddress, sitePostcode, siteContactName,
        siteContactNumber, dateAttended, dateDue, jobCost } = props;
    const { setShowJobDetailsModal, setDetailsJobModalID } = useContext(Context);
    const attendedDate = new Date(dateAttended);
    let attendedDay = attendedDate.getDate();
    let attendedMonth = attendedDate.getMonth() + 1;
    let attendedYear = attendedDate.getFullYear();
    const dueDate = new Date(dateDue);
    let dueDay = dueDate.getDate();
    let dueMonth = dueDate.getMonth() + 1;
    let dueYear = dueDate.getFullYear();
    const jobID = props._id;

    return (
        <>
            <Card body style={{ marginBottom: '20px' }}>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={6} lg={3} >
                            <FontAwesomeIcon icon={faStoreAlt} /> {siteName}<br />
                            <FontAwesomeIcon icon={faTruckFast} /> {jobName}
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <FontAwesomeIcon icon={faAddressBook} /> {siteAddress}<br />
                            <FontAwesomeIcon icon={faLocationDot} /> {sitePostcode}
                        </Col>
                        <Col xs={12} md={6} lg={2}>
                            <FontAwesomeIcon icon={faUserTie} /> {siteContactName}<br />
                            <FontAwesomeIcon icon={faPhone} /> <a href={"tel:" + siteContactNumber}>{siteContactNumber}</a>
                        </Col>
                        <Col xs={12} md={6} lg={4} style={{ paddingTop: '0px' }}>
                            <Row>
                                <Col xs={12} lg={4}>
                                    <p style={{ fontWeight: 'bold' }}>{attendedDay}/{attendedMonth}/{attendedYear}<br />
                                        <FontAwesomeIcon icon={faMoneyBill} /> £{jobCost.toFixed(2)}</p>
                                </Col>
                                <Col xs={12} lg={8}>
                                    <Button style={{
                                        float: 'right', width: '100%', marginBottom: '10px',
                                        backgroundColor: '#6AAE3E', borderColor: '#6AAE3E'
                                    }} onClick={() => {
                                        setDetailsJobModalID(jobID);
                                        setShowJobDetailsModal(true);
                                    }}>
                                        DETAILS <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </Button>

                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    <p style={{ width: '100%', textAlign: 'right', marginBottom: '0px' }}>
                        Due: {dueDay}/{dueMonth}/{dueYear} | Attended: {attendedDay}/{attendedMonth}/{attendedYear}
                    </p>
                </Container>
            </Card>
        </>
    );
}

export default JobSubContractorContract;