import { useContext } from "react";
import Contract from "./ListPagesItems/Contract";
import { Context } from "../../../lib/Context";
import Container from 'react-bootstrap/Container';
const ListContracts = props => {
    const { type } = props;
    const { contracts } = useContext(Context);
    return (
        <Container style={{ marginBottom: '30px', marginTop: '60px' }}>
            {type === 'live' ?
                contracts?.length === 0 ?
                    'There are no contracts associated with your account.' : contracts?.map((contract, index) => (
                        contract.isLive &&
                        <Contract key={contract._id + index} {...contract} />
                    ))
                :
                contracts?.length === 0 ?
                    'There are no contracts associated with your account.' : contracts?.map((contract, index) => (
                        !contract.isLive &&
                        <Contract key={contract._id + index} {...contract} />
                    ))
            }
        </Container>
    )
}
export default ListContracts;