import Form from 'react-bootstrap/Form';

const LoginForm = props => {
    const { buildEmail, buildPassword, emailIsValid, userType, userDataRetrieved, hasPasswordResetCode,
        userHasPassword, setPasswordsMatch, password, setResetPassword, email, securityCode,
        setSecurityCode, emailVerified, setVerificationCode, verificationCode } = props;

    const buildSecurityCode = (event) => {
        const code = event.target.value;
        setSecurityCode(code);
    }

    const buildVerificationCode = (event) => {
        const code = event.target.value;
        setVerificationCode(code);
    }

    const confirmPassword = (event) => {
        const currentPassword = event.target.value;
        if (currentPassword === password) {
            setPasswordsMatch(true);
        } else {
            setPasswordsMatch(false);
        }
    }

    return (
        <Form>
            {
                <>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Please enter in your email address{emailIsValid && ' and password'}.</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onInput={buildEmail} />
                    </Form.Group>
                    {!hasPasswordResetCode && (
                        emailIsValid && (userType === 'admin' || userType === 'sub-contractor' || userType === 'client' || userType === 'site') &&
                            userHasPassword && userDataRetrieved && !hasPasswordResetCode && emailVerified ? // Sub-User has set password
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="password" autoComplete="password" placeholder="Password" onChange={buildPassword} />
                            </Form.Group>
                            : emailIsValid && (userType === 'admin' || userType === 'sub-contractor' || userType === 'client' || userType === 'site') &&
                                !userHasPassword && emailVerified && userDataRetrieved ? // Sub-User needs to set password
                                <>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Control type="password" autoComplete="password" placeholder="Password" onChange={buildPassword} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
                                        <Form.Control type="password" autoComplete="password" placeholder="Password" onChange={confirmPassword} />
                                    </Form.Group>
                                </>
                                :
                                !emailVerified && userDataRetrieved ?
                                    <>
                                        <Form.Group className="mb-3" controlId="formVerificationCode">
                                            <Form.Label>Please enter the security code that was emailed to your email address.</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Verification Code" value={verificationCode}
                                                onInput={buildVerificationCode} />
                                        </Form.Group>
                                    </>

                                    :
                                    emailIsValid && userDataRetrieved && // User not recognised
                                    ''
                    )}
                </>
            }
            {hasPasswordResetCode && userDataRetrieved &&
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Please enter the security code that was emailed to your email addressy.</Form.Label>
                    <Form.Control type="text" placeholder="Enter Security Code"
                        value={securityCode} onInput={buildSecurityCode} />
                </Form.Group>

            }

            {userHasPassword &&
                <div>
                    <p style={{ textAlign: 'center', cursor: 'pointer', color: '#0d6efd' }}
                        onClick={() => { setResetPassword(true) }}>
                        Forgot Password?
                    </p>
                </div>
            }
        </Form>

    )
}

export default LoginForm;