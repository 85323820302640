import { useContext, useState } from 'react';
import { Context } from '../../lib/Context';
import SideNav from './SideNav';

// Modal imports
import AddSiteModal from '../Modals/AddModals/AddSiteModal';
import AddClientModal from '../Modals/AddModals/AddClientModal';
import AddContractModal from '../Modals/AddModals/AddContractModal';
import EditContractorModal from '../Modals/EditModals/EditContractorModal';
import AuthModal from '../Modals/AuthModal'
import AddSubContractorModal from '../Modals/AddModals/AddSubcontractorModal';
import ListSiteModal from '../Modals/ListSiteModal';
import JobDetailsModal from '../Modals/JobDetailsModal';
import CompleteJobModal from '../Modals/CompleteJobModal';
import ApproveContractModal from '../Modals/ApproveContractModal';
import ContactUsModal from '../Modals/ContactUsModal';
import EditClientModal from '../Modals/EditModals/EditClientModal';
import EditContractModal from '../Modals/EditModals/EditContractModal';
import EditJobModal from '../Modals/EditModals/EditJobModal';
import EditSiteModal from '../Modals/EditModals/EditSiteModal';
import EditSubContractorModal from '../Modals/EditModals/EditSubContractorModal';
import PerJobInvoiceModal from '../Modals/PerJobInvoiceModal';
import ValidateEmailModal from '../Modals/ValidateEmailModal';
import '../../css/NavBar.css'
import '../../css/SideNav.css'
import TopNavBar from './TopNavBar';
import MonthlyInvoiceModal from '../Modals/MonthlyInvoiceModal';
import InvoiceModal from '../Modals/InvoiceModal';

const NavMain = (props) => {
    const { logout, setShowLoginModal, emailIsValid, setEmailIsValid, infoRetrieved, getUserProfile,
        getSubContractorJobs, displayPage, setDisplayPage } = props;
    const { showLoginModal, userInfo, handleSwitchPage } = useContext(Context);
    const email = userInfo?.email;
    const [state, setState] = useState(false);

    const handelLoggedOutDisplayPage = (page) => {
        setDisplayPage(page);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        // eslint-disable-next-line
        setState({ ...state, ['left']: open });
    };

    return (
        <>
            <TopNavBar logout={logout}
                setShowLoginModal={setShowLoginModal}
                infoRetrieved={infoRetrieved}
                displayPage={displayPage}
                handelLoggedOutDisplayPage={handelLoggedOutDisplayPage}
                toggleDrawer={toggleDrawer}
                state={state} />
            <SideNav state={state}
                toggleDrawer={toggleDrawer}
                anchor={'left'}
                handleSwitchPage={handleSwitchPage}
                infoRetrieved={infoRetrieved}
                handelLoggedOutDisplayPage={handelLoggedOutDisplayPage}
            />
            <AuthModal
                showLoginModal={showLoginModal}
                emailIsValid={emailIsValid}
                setEmailIsValid={setEmailIsValid}
                getUserProfile={getUserProfile}
                getSubContractorJobs={getSubContractorJobs}
            />

            <AddClientModal />
            <AddSiteModal />
            <AddContractModal />
            <AddSubContractorModal />
            <ListSiteModal />
            <JobDetailsModal />
            {email && <PerJobInvoiceModal />}
            {email && <MonthlyInvoiceModal />}
            {email && <InvoiceModal />}
            <CompleteJobModal />
            <ApproveContractModal />
            <ContactUsModal />
            <EditClientModal />
            <EditContractModal />
            <EditJobModal />
            <EditSiteModal />
            <EditSubContractorModal />
            <EditContractorModal />
            <ValidateEmailModal />
        </>
    )
}

export default NavMain;