import { useContext } from 'react';
import { Context } from '../../lib/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faRightFromBracket, faSignIn } from '@fortawesome/free-solid-svg-icons';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/esm/Button';
// eslint-disable-next-line
import navLogo from '../../lib/Images/nav-logo-alt-center.png';
import '../../css/NavBar.css'

const NavBar = (props) => {
    const { logout, setShowLoginModal, infoRetrieved, toggleDrawer } = props;
    const { userInfo } = useContext(Context);
    const email = userInfo?.email;

    return (
        <Navbar bg="dark" expand="lg" variant="dark" fixed="top">
            <Container fluid>
                <Navbar.Brand>
                    <img className="d-none d-sm-block" src={navLogo} alt="Website Logo" style={{ maxWidth: '300px', marginTop: '0px' }} />
                </Navbar.Brand>
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Text>
                    {infoRetrieved &&
                        !email ?
                        <>
                            <Button style={{ marginRight: '10px' }} variant="secondary" onClick={toggleDrawer('left', true)}>
                                MENU <FontAwesomeIcon icon={faBars} />
                            </Button>
                            <Button onClick={() => { setShowLoginModal(true) }} style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                                className="admin-link">
                                LOGIN <FontAwesomeIcon icon={faSignIn} />
                            </Button>
                        </>
                        :
                        <>
                            {/* <Navbar.Text>
                                    <span className="admin-link">{email}</span>
                                </Navbar.Text> */}
                            <Button style={{ marginRight: '10px' }} variant="secondary" onClick={toggleDrawer('left', true)}>
                                MENU <FontAwesomeIcon icon={faBars} />
                            </Button>
                            <Button style={{ backgroundColor: '#E6893B', borderColor: '#E6893B' }} onClick={logout} className="admin-link">
                                LOGOUT <FontAwesomeIcon icon={faRightFromBracket} />
                            </Button>
                        </>
                    }
                </Navbar.Text>
            </Container>
        </Navbar>
    )
}

export default NavBar;