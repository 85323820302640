import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Context } from '../lib/Context';

const LoginButtons = (props) => {
    const { isLogin, userDataRetrieved, userLogin, userHasPassword, passwordsMatch, // registerUser,
        resetPassword, resetUserPassword, checkPasswordResetCode, hasPasswordResetCode, setUserPassword,
        emailVerified, checkVerificationCode } = props
    const { userType } = useContext(Context)
    return (
        <>
            {isLogin ?
                userHasPassword && userDataRetrieved && !resetPassword && hasPasswordResetCode ? // Sub-User has set password
                    <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} onClick={checkPasswordResetCode}>
                        Check Security Code
                    </Button>
                    :
                    userHasPassword && userDataRetrieved && resetPassword ?
                        <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} onClick={resetUserPassword}>
                            Reset Password
                        </Button>
                        :
                        userHasPassword && userDataRetrieved && !resetPassword && emailVerified ? // User is admin
                            <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} onClick={userLogin}>
                                User Login
                            </Button>
                            :
                            (userType === 'admin' || userType === 'client' || userType === 'sub-contractor' || userType === 'site') &&
                                !userHasPassword && emailVerified && userDataRetrieved ? // Sub-User needs to set password
                                passwordsMatch ?
                                    <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }} onClick={setUserPassword}>
                                        Set Password
                                    </Button>
                                    :
                                    <Alert variant="success" style={{ minWidth: '100%', textAlign: 'center' }}>
                                        Please enter in matching passwords!
                                    </Alert>
                                :
                                !emailVerified && userDataRetrieved ?
                                    <Button style={{ borderColor: '#3D91F6', backgroundColor: '#3D91F6' }}
                                        onClick={checkVerificationCode}>
                                        Check Code
                                    </Button>
                                    :
                                    userType === '' && userDataRetrieved && // User not recognised
                                    <Alert variant="danger" style={{ minWidth: '100%', textAlign: 'center' }}>
                                        Sorry this email is not recognised!
                                    </Alert>
                :
                ''
            }
        </>
    )
}

export default LoginButtons